.upload {
  &__box {
    outline: 2px dashed #ced4da;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 25px;
    //border: 1px dashed #CED4DA;
    border-radius: 3px;
    transition: 0.2s;
  }

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &__box label {
    width: 100%;
    cursor: pointer;
  }

  &__box span {
    display: block;
  }

  &__box span.is-fake {
    color: #0081c3;
    text-decoration: underline;
  }
}
