$background-light: hsl(0, 0%, 96%);
$border-radius: 70px;

div.progress-bar {
  height: 7px;
  border-radius: 10px;
  overflow: hidden;
  border-radius: $border-radius;

  div.progress-bar-inner {
    width: 0%;
    height: 100%;
    transition: width 1s;
    border-radius: $border-radius;
  }
}
