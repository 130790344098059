.react-datepicker {
  .react-datepicker__header {
    background-color: #3db3e2;
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-name {
      color: #ffffff;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
      background-color: #3db3e2;
    }
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ffffff;
    &:hover {
      border-right-color: #ffffff;
    }
  }
  .react-datepicker__navigation--next {
    border-left-color: #ffffff;
    &:hover {
      border-left-color: #ffffff;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: #3db3e2;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom'] {
    border-bottom-color: #3db3e2;
    &::before {
      border-bottom-color: #3db3e2;
    }
  }
  .react-datepicker__triangle {
    &::before {
      border-bottom-color: #3db3e2;
    }
  }
}
