.elements {
  &__body {
  }

  &__container {
    background-color: #ffffff;
    border: 1px solid #e5e9f2;
    border-radius: 3px;
    box-sizing: border-box;
    margin-top: 26.5px;
    position: relative;
    width: 100%;
  }
  &__error {
    margin: 24px 24px -5px 24px !important;
  }
}
