html,
body {
  overflow: hidden; }

strong {
  font-weight: 700 !important; }

.fixed-items {
  left: 0;
  position: fixed; }
  .fixed-items.open {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    display: block;
    top: 64px;
    width: 100%; }
  .fixed-items .logo-container {
    background-color: white;
    border-bottom: 1px solid #000;
    border-right: 1px solid #d2d2d2;
    height: 64px;
    text-align: center;
    top: 0; }
    .fixed-items .logo-container .turnable-logo {
      padding-top: 12px;
      width: 166px; }
  @media screen and (min-width: 768px) {
    .fixed-items {
      top: 0;
      width: 0; }
      .fixed-items.open {
        width: 0; } }

.view-port {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: left 500ms; }
  @media screen and (max-width: 1023px) {
    .view-port {
      left: 0;
      width: 100%; } }
  .view-port.is-touch {
    left: 0;
    width: 100%; }
  .view-port.is-main-site {
    top: 64px;
    height: calc(100% - 64px); }
  @media screen and (min-width: 768px) {
    .view-port.has-open-sidebar {
      left: 275px;
      width: calc(100% - 275px); } }

.header {
  margin-bottom: 1.2rem; }

button.main-button, button.is-success {
  font-size: 16px; }

button.is-primary,
button.is-primary:active,
button.is-primary:hover,
button.is-primary:focus,
button.is-primary:disabled {
  background-color: #3cb3e2 !important; }

button.is-danger,
button.is-danger:active,
button.is-danger:hover,
button.is-danger:focus,
button.is-danger:disabled {
  background-color: #e93f33 !important; }

button.is-secondary,
button.is-secondary:active,
button.is-secondary:hover,
button.is-secondary:focus,
button.is-secondary:disabled,
div.is-secondary,
div.is-secondary:active,
div.is-secondary:hover,
div.is-secondary:focus,
div.is-secondary:disabled {
  background-color: #fff;
  border: 1px solid #3cb3e2;
  color: #3cb3e2; }

button.is-third,
button.is-third:active,
button.is-third:hover,
button.is-third:focus,
button.is-thirdy:disabled {
  background-color: #0081c3;
  border: 1px solid #0081c3;
  color: #fff; }

.auth0-lock.auth0-lock
.auth0-lock-social-button.auth0-lock-social-big-button
.auth0-lock-social-button-text {
  color: #000; }

/*[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not([disabled])::after {
  border-color: transparent transparent rgba(74, 74, 74, 0.2) transparent !important;
}*/
[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not([disabled])::before {
  background-color: #fff;
  border: 1px solid lightgrey;
  color: #0d264f; }

.wrapping-container {
  flex-wrap: wrap; }

[aria-disabled='false'] {
  cursor: unset !important; }

.break {
  flex-basis: 100%;
  height: 0; }

.error-container {
  background-color: #ffffff;
  border: 1px solid #e93f33;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px 16px;
  text-align: center;
  width: 100%; }
  .error-container .error-description {
    color: #e93f33;
    font-weight: bold; }

.caveat-container {
  background-color: #FFFFFF;
  border: 1px solid #E5E9F2;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px 16px;
  text-align: left;
  width: 100%; }
  .caveat-container .caveat-description {
    font-size: 12px;
    font-style: oblique;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px; }

.help-container {
  color: #122048;
  display: inline-block;
  font-weight: 300;
  height: 23px;
  position: absolute;
  text-align: center;
  text-transform: none;
  width: 23px; }
  .help-container:hover .help-body {
    display: block; }
  .help-container .help-body {
    background: white;
    border: 1px solid #e5e9f2;
    top: 33px;
    content: '';
    display: none;
    max-height: none;
    padding: 13px 16px;
    position: absolute;
    right: -31px;
    z-index: 1; }
    .help-container .help-body:hover {
      display: block; }
    .help-container .help-body::before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(74, 74, 74, 0.9);
      content: '';
      height: 0;
      right: 31px;
      position: absolute;
      top: -10px;
      width: 0; }
    .help-container .help-body .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; }
    .help-container .help-body .list {
      border: 0;
      box-shadow: none;
      margin-top: 8px;
      padding-left: 10px; }
      .help-container .help-body .list .highlighted .label,
      .help-container .help-body .list .highlighted .value {
        color: #0081c3; }
      .help-container .help-body .list .label {
        min-width: 130px; }
      .help-container .help-body .list .label,
      .help-container .help-body .list .value {
        display: inline-block;
        font-size: 12px;
        font-weight: 300; }
