* {
  box-sizing: border-box; }

.field {
  width: 100%;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out; }

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05); }

.field.active {
  background-color: #ffffff; }

.field input[type=text]:focus {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding-left: 16px; }

.field.locked {
  pointer-events: none; }

.field input {
  width: 100%;
  position: relative;
  border: none;
  border-radius: 4px;
  line-height: normal;
  background-color: transparent;
  color: #363636;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none; }

.field input::-webkit-input-placeholder {
  color: #6b6d6e; }

.field input::-moz-placeholder {
  color: #6b6d6e; }

.field input:-ms-input-placeholder {
  color: #6b6d6e; }

.field input:-moz-placeholder {
  color: #6b6d6e; }

.field.has-text-centered .edit-field__input {
  text-align: center; }

.field .edit-field__input {
  height: 1.5em;
  padding-bottom: 0;
  padding-top: 0; }

.field:not(:last-child) {
  margin-bottom: unset !important; }
