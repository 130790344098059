.turnboard-schedule-container {
    height: auto;
    background-color: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 40px 40px 7px;
    border-radius: 3px;
    padding: 21px 16px 8px;
    position: relative;

    .notification {
        align-items: center;
        padding: 10px 15px !important;
        font-size: 12px;
        margin-top: 0px;

        button {
            background-color: transparent;
            border: none;
            padding: 0px;
            cursor: pointer;
            margin-left: auto;
        }
    }

    .turnboard-schedule-scroll {
        overflow-y: auto;
        max-height: 148px;

        &::-webkit-scrollbar {
            width: 5px;
            background-color: transparent;
            position: absolute;
            right: 0;
        }

        &::-webkit-scrollbar-track {
            box-shadow: transparent;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 7px;
            outline: none;
        }
    }

    .close-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: transparent;
        padding: 0;
        border: 0;
        cursor: pointer;
    }

    h3 {
        color: #122048;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.36px;
        line-height: 22px;
        margin-bottom: 8px;
    }

    .select {
        width: 100%;
    }

    h4 {
        color: #021D49;
        font-size: 12px;
        font-weight: 900;
        line-height: 16px;
        margin-bottom: 12px;
    }

    .choose-service-div {
        max-width: 168px;
        width: 100%;
        margin-right: 16px;
    }

    .pick-time-div {
        max-width: 504px;
        width: 100%;
        margin-right: 8px;
    }

    .pick-time-row {
        width: 100%;
    }

    .checkbox {
        margin-top: 19px;

        .checkbox-inner {
            height: 12px;
            width: 12px;

            &::after {
                height: 5px;
                left: 3px;
            }
        }

        .checkbox-label {
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 16px;
            padding-left: 8px;
        }
    }

    .un-schedule-units {
        margin-top: 10px !important;
    }

    .solution-button {
        width: 100%;
        max-width: 164px;
        margin-right: 4px;
        margin-bottom: 4px;

        &.isDisabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        input[type="radio"] {
            display: none;
        }

        label {
            white-space: break-spaces;
        }

        .solution-button-checked {
            border-color: #E5E9F2;
            border-radius: 13px;
            background-color: #FFFFFF;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 16px;
            color: #122048;
            padding: 7px 15px;
            display: flex;
            align-items: center;
            height: auto;
            max-width: 164px;

            &.isDisabled {
                cursor: not-allowed;
            }

            &.active-check {
                border-color: #55C2B8;
                background-color: #55C2B8;
                color: #ffffff;
                font-weight: 900;
            }
        }

        .date-label {
            display: block;
        }
    }

    .select-date-div {
        max-width: 336px;
        width: 100%;
        margin-right: 8px;
    }

    .Schedule-submit-div {
        max-width: 162px;
        width: 100%;
        display: flex;
        justify-content: right;
        padding-top: 28px;
        padding-right: 15px;
        margin-left: auto;

        .button {
            padding: 12px 20px;
            font-size: 16px;
            line-height: 22px;
            height: 38px;

            &.is-primary {
                &.is-outlined {
                    color: #ffffff;
                }
            }
        }
    }
}
