.template-list-container {
  height: calc(100vh - 150px);
  //background-color: #ffffff;
  border-radius: 3px;
  .data-table-header {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
      }
      .is-success {
        &:hover {
          background-color: #86c453 !important;
        }
      }
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
      .template-action-buttons {
        button {
          margin-right: 12px;
        }
        .is-primary-outlined {
          color: #3db3e2;
          border: 1px solid #3db3e2;
        }
      }
    }
    .more-action-button {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 10px;
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        cursor: pointer;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
    }
  }
}

.setup-vendor-service-row {
  height: 40px;
  line-height: 40px;
  padding: 0 20px 0 53px;
  border-top: 1px solid #eef1f6;
}
.setup-vendor-service-header {
  border-bottom: '2px solid #eef1f6';
  margin-left: 0 !important;
  margin-top: 0 !important;
  padding: 1rem 90px 2rem 20px;
  height: 4rem;
}
.setup-vendor-service-add-row {
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 0.5rem;
  border-top: 1px solid #eef1f6;
}
.setup-vendor-add-button {
  margin-top: -3px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
}
