.qc-queue {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 
    'header'
    'main';

  &-content {
    background: #FFFFFF;
    padding: 8px 16px 0px 16px;
    grid-area: main;
    overflow: auto;
  }

  .check-button {
    position: absolute;
    padding: 8px;
    top: 0;
    left: 0;
  }

  .assign-popup {
    position: absolute;
    bottom: 76px;
    left: 11px;
    right: 11px;
    border: 1px solid #959697;
    border-radius: 4px;
    height: auto;
    z-index: 999999;

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .divider {
    height: 1px;
    background-color: #ececf0;
    margin: 16px;
  }

  &-card{

    &-unit {
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }
}
