@import "~@bryntum/gantt/gantt.material.css";

.boards-body {
  display: flex;

  // .b-gantt-task-wrap {
  //   $elements: 1000;
  //   $translateValue: 5px;

  //   @for $i from 1 to $elements {
  //     &:nth-child(#{$i}) {
  //       transform: translateY($translateValue);
  //     }
  //     $translateValue: $translateValue + 36px
  //   }
  // }

  .gantt-container {
    border: 1px solid #E5E9F2;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;

    .b-gantt-task {
      border-radius: 50px !important;
      clip-path: none !important;
      overflow: hidden !important;
      position: relative;

      &.b-task-selected {
        &:after {
          background: rgba(0,0,0, .3);
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1000;
        }

        .percent-label {
          font-weight: bold;
        }
      }

      // % progress style hidden for child items
      // &::after {
      //   background: rgba(255, 255, 255, .8);
      //   content: '';
      //   display: block;
      //   height: 100%;
      //   width: 100%;
      //   position: absolute;
      // }
    }

    .b-gantt-task-parent {
      &::after {
        background: rgba(255, 255, 255, .8);
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
      }

      .percent-label {
        color: #122048;
        display: block;
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 11px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    // % progress style hidden for child items
    .percent-label {
      display: none;
      // color: #122048;
      // font-size: 8px;
      // font-weight: 300;
      // letter-spacing: 0;
      // line-height: 11px;
      // position: absolute;
      // right: 5px;
      // top: 50%;
      // transform: translateY(-50%);
    }

    .b-task-percent-bar-outer {
      background: inherit;
    }

    .b-task-percent-bar {
      background: inherit;
      position: absolute;
      z-index: 1000;
    }

    .b-grid-header {
      background: white;
      
      .b-grid-header-text-content {
        color: #122048;
        font-weight: 500;
      }
    }

    .b-tree-expander {
      .b-icon {
        color: #122048;
        font-size: .8em;
      }

      &~.b-tree-cell-value {
        font-size: 14px;
        font-weight: 500;
      }
    }
    
    .b-tree-cell-value {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 19px;
    }

    .b-action-cell {
      display: none;
    }

    .b-tree-parent-cell {
      ~.b-action-cell {
        display: inherit;
      }
    }

    .b-sch-header-timeaxis-cell {
      background: white;
      color: #122048;
      font-size: 8px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 12px;
      text-align: center;

      .b-sch-header-text {
        font-size: 12px;

        .day-cell {
          font-size: 8px;
        }
      }
    }
    
    .day-cell-item {
      display: block;

      &.dimmed {
        color: #91989E;
      }
    }

    .month-selector {
      // min-width: 310px;
      // max-width: 310px;

      .b-field-inner {
        border-bottom: 0;
      }
      
      .b-icon-calendar {
        display: none;
      }

      .b-align-start,
      .b-align-end {
        color: #122048;
        font-size: .8em;
        padding: 0;
      }

      .b-align-start {
        padding-right: 0;
      }

      input {
        color: #122048;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.36px;
        padding: 0;
        pointer-events: none;
        text-align: center;
      }
    }
  }
}

.gantt-tooltip {
  background: #E5E9F2 !important;
  color: #122048 !important;
  font-family: Avenir !important;
  max-width: 190px;
}