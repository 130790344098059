$grey-lighter: hsl(0, 0%, 93%);

div.progress-card {
  box-shadow: none;
  border: 1px solid $grey-lighter;
  height: 215px;
  padding-top: 14px;
}

.caption {
  font-size: 12px;

  &.info-item {
    margin-top: 4px;
  }
}

.stat {
  font-weight: 900 !important;
  margin-top: 2px;
}
