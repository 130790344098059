.data-table-wrapper {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  min-height: 200px;
  .data-table-header {
    border: 0px;
    border-bottom: 1px solid #e5e9f2;
  }
  .data-table-body {
    &>div {
      overflow: visible;
    }
  }
}
