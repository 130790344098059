div.basic-modal {
  .modal-card-body {
    padding: 30px 40px 20px 40px;
    max-height: 580px;
    overflow: auto;
  }

  .close {
    position: absolute;
    right: 8.5px;
    top: 8.5px;
  }

  header.modal-card-head {
    height: 50px;
  }

  button.primary-action {
    position: absolute;
    right: 20px;
  }
}
