.more-info {
  position: relative;

  &::after {
    background: url('./icons/key-legend.png');
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    display: none;
    content: '';
    left: -145px;
    height: 220px;
    top: 38px;
    position: absolute;
    width: 396px;
    z-index: 1;
  }

  &.active {
    &::after {
      display: block;
      z-index: 10;
    }
  }

  @media screen and (min-width: 768px) {
    &::after {
      left: 0px;
      top: 38px;
    }
  }

  @media screen and (min-width: 1024px) {
    &::after {
      left: -288px;
      top: 38px;
    }
  }
}
