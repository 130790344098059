.services-select {
  .css-b62m3t-container,
  .css-1s2u09g-control,
  .css-129yd5r-ValueContainer {
    min-height: 35px !important;
    font-size: inherit !important;
  }
  .css-1s2u09g-control {
    border-color: #e5e9f2 !important;
  }
  .css-1insrsq-control {
    background-color: transparent !important;
    border-color: whitesmoke !important;
  }
}
.is-pointer {
  cursor: pointer;
}
.select-input {
  width: 92.5%;
  height: 35px;
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  background-color: #ffffff;
  border-color: #e5e9f2;
  border-radius: 4px;
  color: #363636;
  appearance: none;
  background-position: right 0.75em center;
  background-image: url('./icons/down-arrow.svg');
  &:focus-visible {
    outline: 0;
  }
}
.info-container {
  display: inline;
  img {
    margin-bottom: 3px;
  }
}
.content {
  padding: 5px;
  box-sizing: border-box;
}
.team-manage-toggle {
  .toggle-control {
    margin-bottom: 10px;
  }
}
