.component-bottomsheet {
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  bottom: 0;
  overflow-y: hidden;
  max-height: 500px;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  display: flex;
  flex-direction: column;
  z-index: 999999;

  &-overlay {
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    background: #000000;
  }

  &-overlay-hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &-overlay-show {
    visibility: visible;
    opacity: 0.2;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &-open {
    bottom: 0;
    height: 500px;
  }

  &-close {
    bottom: 0;
    height: 0;
  }

  &-header {
    display: flex;
    padding: 16px;

    &-title {
      flex: 1;
      font-size: 24px !important;
    }
  }

  &-content {
    padding: 0px 16px 16px 16px;
    flex: 1;
  }

  &-footer {
    padding: 0px 16px 16px 16px;
  }
}