.qc-work {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 
    'header'
    'main';

  &-content {
    background: #FFFFFF;
    padding: 8px 16px 0px 16px;
    grid-area: main;
    overflow: auto;
  }

  &-header {
    margin-bottom: 16px;
  }

  &-cards {
    margin-top: 16px;
  }
}