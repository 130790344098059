div.big-loading {
  width: 100%;

  p.loading-text {
    font-size: 25px;
    padding-left: 20px;
  }

  .loading-spinner {
    margin-top: 33%;
    margin-bottom: -20px;
    font-size: 100px;
  }
}
