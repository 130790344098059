@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/functions';

$primary: #3cb3e2;
$primary-invert: findColorInvert($primary);
$link: #0081c3;
$info: #55c2b8;
$success: #8dc75c;
$danger: #ea3f32;

@import '~bulma/sass/utilities/derived-variables';
@import '~bulma/sass/utilities/animations';
@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/utilities/controls';

$text: #0d264f;

$menu-item-hover-background-color: $primary;
$menu-item-active-background-color: $primary;
$menu-item-hover-color: $link;
$menu-item-color: $link;
$menu-list-line-height: 2rem;

$navbar-height: 4rem;

$dropdown-menu-min-width: calc(100% - 16px);
$dropdown-content-offset: 1px;

$section-padding: 0.8rem 2.5rem;

$button-focus-border-color: transparent;
$button-focus-box-shadow-color: transparent;
$button-active-border-color: transparent;
$button-static-border-color: transparent;

$table-head-cell-border-width: 0 0 1px;
$pagination-disabled-background-color: #fff;
$pagination-background-color: #fff;

$progress-bar-background-color: #fff;

$border: #e5e9f2;
