.boards-content {
  height: calc(100% - 100px);
  position: relative;
  top: 30px;
  width: 100%;
}

.boards-calendar {
  border: 1px solid #e5e9f2;
}

.boards-body {
  height: 100%;
}
