.invoice-item {
    &:hover {
        & .invoice-item__actions--show {
            display: block;
        }
    }

    &__actions {
        display: none;
    }

    &__edit {
        margin-left: 0 !important;
    }
 
    &__td {
        padding-top: 19px !important;
        padding-bottom: 19px !important;

        &--first {
            padding-left: 40px !important;
        }
        &--last {
            padding: 0 40px 0 0 !important;
            vertical-align: middle !important;
            width: 100px !important;
        }

        &--first-input {
            padding-left: 30px !important;
        }

        &--with-input {
            padding-top: 13px !important;
            padding-bottom: 13px !important;
        }
    }

    & .edit-field__input:focus {
        background-color: #e5e9f2;
        border: 1px solid #0081c3;
    }
 
    & .edit-field__input:disabled {
        background-color: transparent;
        color: #363636;
    }

    & .asterisk {
        float: left;
        margin-left: 0px !important;
        padding-right: 5px;
    }

    & .total {
        padding: 5px;
    }

    .p-b-6 {
        padding-top: 6px;
    }
}

.invoice-item__margin-title {
    margin-left: 0 !important;
}
