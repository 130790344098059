$primary: #3eb3e2;
.services-main {
  .table-head-tab {
    color: #000;
    &.is-active {
      border-bottom: 2px solid $primary;
    }
  }
}

.unit-toolbar-nav {
  background: #fff;
  margin-top: 4px;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
  }
  a {
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: flex-start;
    color: #626a6a;
    &:hover,
    &:focus {
      background: #fff;
      color: #122149;
    }
  }
}

input:checked + .transfers-switch:before {
  -webkit-transform: translateX(86px) !important;
  -ms-transform: translateX(86px) !important;
  transform: translateX(86px) !important;
}

.tabs {
  a:first-child {
    padding-left: 0 !important;
  }
  a {
    padding-top: 0 !important;
  }
  ul {
    border: none !important;
  }
}

.action-container {
  .button {
    &:not(.is-hidden) {
      & ~ .calendar-container {
        &:nth-child(2) {
          margin-left: 11px;
        }
      }
    }
  }
}

.filter-dropdown {
  .dropdown-menu {
    left: 62px;
    z-index: 100;
  }
}
.block-toggle-container {
  .toggle-control {
    margin-bottom: 19px;
  }
}
.toggle-text {
  padding-right: 17px;
}

.link {
  color: #3db3e2;
  text-decoration-line: underline;
  line-height: 15px;
  cursor: pointer;
  &:hover {
    color: #3db3e2;
  }
}

.dsds {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
