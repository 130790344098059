.vendor-pending {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 
    'header'
    'main';

  &-content {
    background: #FFFFFF;
    padding: 16px 16px 0 16px;
    grid-area: main;
    overflow: auto;
  }

  &-cards {
    margin-top: 24px;
  }

  &-empty {
    height: calc(100vh - 215px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}