.inline-tab-options {
  &-container {
    margin-bottom: 16px;
  }

  li {
    border-bottom: 2px solid white;
    color: #122048;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    margin-right: 12px;
    padding: 6px 0;

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      border-bottom: 2px solid #3DB3E2;
      color: #3DB3E2;
      font-weight: 600;
    }
  }
}
