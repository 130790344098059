.schedule-second-step {
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 3px;
  box-sizing: border-box;
  height: auto;
  margin: 23px auto;
  max-width: 839px;
  padding: 40.5px 25px;
  position: relative;
  text-align: center;
  width: 100%;

  .title {
    color: #122048;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 41px;

    .highlighted {
      color: #8dc85c;
      font-weight: bold;
    }
  }

  .button {
    &.is-primary {
      background: #8dc85c !important;
    }
  }

  .navigation-container {
    bottom: -60px;
  }
}
