@import "~@bryntum/calendar/calendar.material.css";

.boards-body {

  // TODO remove this hack when the library has fixed the properties that disallow the drag behaviors
  .b-weeks-container {
    pointer-events: none;
  }

  .b-dayview-allday-row-start,
  .b-timeaxis-container {
    display: none; 
  }

  .b-cal-event-body {
    padding: 0 0.5em 0 0.5em !important;

    .percentage {
      display: none;
    }
  }

  .b-cal-cell-header,
  .b-cal-event-wrap,
  .b-cal-cell-overflow {
    pointer-events: all;
  }

  .b-calendar-day-header {
    color: #122048 !important;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }

  .b-calendar-cell {
    .b-day-name {
      color: #122048;
    }

    &.b-nonworking-day {
      .b-day-name {
        color: #122048;
      }
    }
  }

  .b-cal-event {
    width: 100% !important;

    &-body {
      height: 100%;
      width: 100%;

      .progress {
        border-radius: 50px;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        
        &-container {
          border-radius: 50px;
          height: 100%;
          line-height: 20px;
          position: relative;
          width: 100%;
          
          .name {
            color: #122048;
            font-size: 11px;
            font-weight: 300;
            height: 100%;
            line-height: 20px;
            left: 10px;
            position: absolute;
            top: 1px;
            z-index: 100;
          }
          
          .percentage {
            color: #122048;
            font-size: 8px;
            font-weight: 300;
            height: 100%;
            line-height: 11px;
            position: absolute;
            right: 8px;
            top: 5px;
            z-index: 100;
          }

          &.week {
            .name {
              top: 5px;
            }

            .percentage {
              top: 10px;
            }
          }
          
          &.day {
            .name {
              top: 5px;
            }

            .percentage {
              top: 10px;
            }
          }
        }
      }
    }

    &-desc {
      height: 100%;
    }

    &-icon {
      display: none;
    }

    .b-event-time {
      display: none;
    }
  }

  .b-calendar-days {
    border-left: 0;
  }

  .b-calendarpanel-content {
    overflow-y: scroll;
    padding: 0 !important;
  }

  .b-calendar-cell {
    min-height: 120px;
  }

  .b-calendar-view-desc {
    color: #122048;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 22px;
    margin-left: .5em;
  }

  .b-panel-content {
    padding: 10px !important;
  }

  .b-toolbar-content {
    align-items: center;
    justify-content: center;
  }

  .b-top-toolbar {
    justify-content: center;
  }

  .b-top-toolbar {
    border-bottom: 0;
    padding-bottom: 0;
  }
  
  .data-selector,
  .mode-selector {
    min-height: 2em !important;

    .b-button {
      background: white;
      border: 1px solid #E5E9F2;
      height: 16px;
      letter-spacing: 0;
      line-height: 16px;
      min-height: 2em !important;
      text-align: center;

      &.b-pressed {
        background: #3DB3E2;
        
        label {
          color: white;
          font-weight: bold;
        }
      }

      label {
        color: #122048;
        font-size: 12px !important;
        font-weight: 300;
        text-transform: capitalize;
      }
    }
  }

  [data-ref="prevButton"],
  [data-ref="nextButton"] {
    background: transparent;
  }

  .b-dayview-content,
  .b-weekview-content {
    border: 1px solid #E5E9F2;
    border-radius: 5px !important;
  }

  .data-selector {
    height: 16px;
    left: 10px;
    position: absolute;
  }

  .mode-selector {
    right: 10px;
    position: absolute;
  }

  .month-container {
    border: 1px solid #E5E9F2;
    border-radius: 5px;
    padding-top: 0;
    // padding-bottom: 40px !important;
  }

  .b-monthview {
    .b-cal-cell-header {
      justify-content: flex-start;
    }

    .b-week-num {
      display: none;
    }
  }

  .day-container,
  .week-container {
    .b-cal-event-wrap {
      width: 100% !important;
    }
  }

  // .b-dayview-allday-row-start,
  // .b-timeaxis-container {
  //   display: none;
  // }

  // .b-dayview-timeaxis-time {
  //   color: white;
  // }

  .b-popup-close {
    display: none !important;
  }

}

.b-overflowpopup-content .b-cal-event-wrap {
  margin: 0 10px 10px 10px !important;
}

.b-tooltip-header {
  background: #0081C3;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.36px;
  line-height: 22px;
}

.b-popup-content {
  background: white;
  width: 250px;

  .title {
    color: #122048;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 8px !important;
    text-transform: capitalize;
  }

  .item {
    color: #122048;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 4px;
    text-transform: capitalize;
  }
}

.b-popup-header {
  background: #0081C3 !important;
  font-weight: bold;
}
