.schedule-first-step {
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 3px;
  box-sizing: border-box;
  height: auto;
  margin: 23px auto 60px auto;
  max-width: 839px;
  padding: 40.5px 25px;
  position: relative;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding: 40.5px 50px;
  }

  .dates-list {
    padding-left: 0;

    li {
      display: inline-block;
      font-size: 16px;
      margin: 0 10px;
    }
  }

  .DayPicker {
    &-Caption {
      font-size: 21px;
      text-align: center;
    }

    // Navigation
    &-NavButton--next {
      background-image: url('./icons/arrow-right.svg');
      right: 36px;
      position: absolute;
      top: 22px;
      width: 14px;
    }

    &-NavButton--prev {
      background-image: url('./icons/arrow-left.svg');
      float: left;
      left: 36px;
      position: absolute;
      top: 22px;
      width: 14px;
    }

    // Day and Weekday
    &-Day,
    &-Weekday {
      font-size: 12px;
      height: 30px;
      width: 30px;

      &:focus {
        outline: none;
      }
    }

    // Modifiers
    &-Day {
      background: #f8f9fa;
      border-radius: 0;
      font-weight: 500;

      &.DayPicker-Day--disabled {
        pointer-events: none;
      }

      &.DayPicker-Day--past,
      &.DayPicker-Day--pastSelection {
        background: transparent;
      }

      &.DayPicker-Day--selected {
        background: #3db3e2;
        border-radius: 50%;
        color: white !important;
      }

      &.DayPicker-Day--firstOfMonth,
      &.DayPicker-Day--today,
      &.DayPicker-Day--weekS {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
      }

      &.DayPicker-Day--lastOfMonth,
      &.DayPicker-Day--weekE {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }

      &.DayPicker-Day--outside {
        background: transparent;
      }
    }

    &-Weekday {
      font-weight: 900 !important;
    }
  }

  .step-button {
    height: auto;
    padding: 16.5px;
    transition: ease-in-out 0.2s;

    &.active {
      border: 1px solid #3db3e2;
      box-shadow: 0 2px 4px 0 #c1c5c9;
      color: #3db3e2;
      font-weight: bold;
    }
  }

  .title {
    color: #122048;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 41px;

    .highlighted {
      color: #3db3e2;
      font-weight: bold;
    }
  }

  .navigation-container {
    bottom: -60px;
  }
}
