.adhoc-damage {
  font-family: Avenir;
  color: #122048;
  .input-set {
    margin-bottom: 10px;
  }
  .input-field {
    input {
      &.form-control {
        width: 100%;
        box-sizing: border-box;
        height: 35px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        padding: 13px 10px;
        outline: none;
        box-shadow: none;
        font-family: Avenir;
        &:hover {
          border-color: #b5b5b5;
        }
        &:focus {
          border-color: #b5b5b5;
        }
      }
      &::placeholder {
        color: #8e8e8e !important;
        opacity: 1;
        font-size: 13px !important;
      }
      ::–webkit-input-placeholder {
        color: #8e8e8e !important;
        opacity: 1;
        font-size: 13px !important;
      }
    }
    textarea {
      &.form-control {
        width: 100%;
        box-sizing: border-box;
        //height: 35px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        padding: 6px 10px;
        outline: none;
        box-shadow: none;
        font-family: Avenir;
        &:hover {
          border-color: #b5b5b5;
        }
        &:focus {
          border-color: #b5b5b5;
        }
      }
    }
    select {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      font-size: 14px;
      padding: 10px 10px;
      outline: none;
      &:focus {
        border-color: #3db3e2;
      }
    }
    .css-14el2xx-placeholder {
      font-size: 14px;
    }
    .css-1s2u09g-control {
      border-color: #e5e9f2 !important;
    }
  }
  .required-star {
    color: red;
  }
  .password-label {
    padding-left: 12px;
    vertical-align: text-bottom;
    cursor: pointer;
  }
  .button-wrapper {
    margin-top: 11px !important;
    display: flex;
    justify-content: center;
  }
  .permission-container {
    max-height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .permission-set-helper-container {
    display: flex;
    justify-content: space-between;
  }
  .permission-label {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    font-weight: bold;
  }
  .permission-bg {
    background-color: #f8f9fa;
  }
  .permission-set {
    padding: 10px;
  }
  .permission-field-set {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    .permission-field {
      width: 32%;
    }
  }
  .link {
    color: #3db3e2;
    text-decoration-line: underline;
    line-height: 15px;
    cursor: pointer;
  }

  .label {
    margin-top: 7px !important;
    font-weight: normal !important;
  }

  .password-error {
    padding-left: 13px;
  }
  .error-button {
    margin-bottom: auto;
  }
  .mb-0 {
    margin-bottom: 0px !important;
  }
  .resident-container {
    border: 1px solid #e5e9f2;
    padding: 10px 12px 3px 12px;
  }
  .label-heading {
    font-weight: 600;
  }
  .error-top-spacing {
    margin-top: -9px !important;
  }
  .permission-delete {
    cursor: pointer;
    margin-right: -8px !important;
  }
  .button-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
  .toggle-container {
    margin-top: 6px !important;
  }
  .is-link {
    color: #3db3e2;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin-top: 10px;
    cursor: pointer;
    outline: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  .is-align-items-center {
    align-items: center !important;
  }

  .is-flex-wrap-wrap {
    flex-wrap: wrap !important;
  }
  .state-description {
    width: 100%;
  }
  .inspection-thumbnail-inner {
    height: 65px;
    width: 52px;
    border: 1px solid #122048;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 15px;
    margin-bottom: 5px;
    overflow-y: clip;
    img {
      width: 100%;
    }
    button {
      position: absolute;
      top: 0;
      right: -8px;
      padding: 0px;
      border: 0px;
      background-color: transparent;
      height: 14px;
      width: 14px;
      cursor: pointer;
      img {
        vertical-align: top;
      }
    }
  }
}
