.vendor-order-detail {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 'header' 'main'; }
  .vendor-order-detail-content {
    background: #FFFFFF;
    grid-area: main;
    overflow: auto; }
  .vendor-order-detail-loading-notes {
    color: #3DB3E2;
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px; }
  .vendor-order-detail-header {
    background: #FFFFFF;
    border-bottom: 1px solid #E5E9F2;
    display: flex;
    padding-right: 64px;
    align-items: center; }
    .vendor-order-detail-header-back {
      margin: 16px;
      width: 48px; }
    .vendor-order-detail-header-title {
      color: #0d264f;
      font-size: 18px;
      font-weight: 300;
      flex: 1;
      text-align: center; }
  .vendor-order-detail-note {
    padding: 20px; }
    .vendor-order-detail-note-title {
      color: #3DB3E2;
      font-size: 14px;
      font-weight: 600; }
    .vendor-order-detail-note-first {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-top: 8px;
      padding-right: 16px; }
  .vendor-order-detail .title {
    color: #0d264f; }
  .vendor-order-detail .level {
    display: flex;
    width: 100%;
    padding: 5px 10px; }
    .vendor-order-detail .level-left, .vendor-order-detail .level-right {
      display: flex; }
  .vendor-order-detail .panel .level-item {
    min-width: 100px;
    max-width: 100px; }

.unit-confirm-complete-dialog {
  position: relative;
  border-radius: 4px; }
  .unit-confirm-complete-dialog .delete {
    position: absolute;
    top: 0;
    right: 0; }
  .unit-confirm-complete-dialog-title {
    margin-bottom: 16px; }
  .unit-confirm-complete-dialog-subtitle {
    margin-bottom: 16px; }
  .unit-confirm-complete-dialog-question {
    margin-bottom: 16px; }

.unit-floorplan-dialog {
  position: relative;
  border-radius: 4px;
  min-height: 300px; }
  .unit-floorplan-dialog .delete {
    position: absolute;
    top: 0;
    right: 0; }

.unit-map-dialog .delete {
  background: black;
  max-height: 22px;
  min-height: 22px;
  max-width: 22px;
  min-width: 22px;
  position: absolute;
  right: 10px;
  top: 0px; }
  .unit-map-dialog .delete::before, .unit-map-dialog .delete::after {
    background: white; }

.order-complete-title {
  color: #122048;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px; }

.vendor-order-unit-detail {
  display: grid;
  grid-template-columns: 1fr 1fr 0.75fr;
  border-bottom: 1px solid #E5E9F2;
  padding-top: 16px;
  padding-bottom: 16px; }
  .vendor-order-unit-detail-unit {
    color: #0d264f;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
    padding-left: 16px; }
  .vendor-order-unit-detail-description {
    padding-left: 16px;
    padding-right: 16px;
    color: #0d264f;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25; }
  .vendor-order-unit-detail-status {
    color: #0d264f;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
    padding-right: 16px; }
  .vendor-order-unit-detail-bold {
    font-weight: 600; }
  .vendor-order-unit-detail-border {
    border-top: 1px solid #E5E9F2; }
