.vendor-section {
  p.heading-4 {
    // font-family: 'Avenir-Book', 'Roboto';
    color: #122048;
    font-size: 24px;
    line-height: 33px;
  }
  .block {
    p.description {
      font-size: 14px;
      // font-family: 'Avenir-Book', 'Roboto';
    }
  }
}
