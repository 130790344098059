.vendor-empty-state {
  background: #FFFFFF;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-image {
    width: 50vw;
    max-width: 300px;
  }

  &-title {
    color: #3DB3E2;
    font-size: 16px;
    font-weight: 900;
    margin-top: 24px;
  }

  &-subtitle {
    color: #122048;
    font-size: 14px;
    font-weight: 300;
    margin-top: 16px;
    width: 224px;
  }
}
