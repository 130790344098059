.invite-staff {
  font-family: Avenir;
  color: #122048;
  .input-set {
    margin-bottom: 10px;
  }
  .input-field {
    input, textarea {
      &.form-control {
        width: 100%;
        box-sizing: border-box;
        height: 35px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        padding: 13px 10px;
        outline: none;
        box-shadow: none;
        font-family: Avenir;
        &:hover {
          border-color: #b5b5b5;
        }
        &:focus {
          border-color: #b5b5b5;
        }
      }
      &::placeholder {
        color: #8e8e8e !important;
        opacity: 1;
      }
      ::–webkit-input-placeholder {
        color: #8e8e8e !important;
        opacity: 1;
      }
    }

    textarea {
      padding: 6px 10px !important;
    }

    select {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      font-size: 14px;
      padding: 10px 10px;
      outline: none;
      &:focus {
        border-color: #3db3e2;
      }
    }
    .css-14el2xx-placeholder {
      font-size: 14px;
    }
    .css-1s2u09g-control {
      border-color: #e5e9f2 !important;
    }
  }
  .required-star {
    color: red;
  }
  .password-label {
    padding-left: 12px;
    vertical-align: text-bottom;
    cursor: pointer;
  }
  .button-wrapper {
    margin-top: 11px !important;
    display: flex;
    justify-content: center;
  }
  
  .link {
    color: #3db3e2;
    text-decoration-line: underline;
    line-height: 15px;
    cursor: pointer;
  }

  .label {
    margin-top: 7px !important;
    font-weight: normal !important;
  }

  .password-error {
    padding-left: 13px;
  }
  .error-button {
    margin-bottom: auto;
  }
  .mb-0 {
    margin-bottom: 0px !important;
  }
  
  .label-heading {
    font-weight: 600;
  }
  .error-top-spacing {
    margin-top: -9px !important;
  }
  .permission-delete {
    cursor: pointer;
    margin-right: -8px !important;
  }
  .button-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
}
