.schedule-dnd {
  display: flex;
  margin-top: 28px;
  height: 100%;

  &-body {
    background-color: #ffffff;
    border: 1px solid #e5e9f2;
    border-radius: 3px;
    box-sizing: border-box;
    flex-grow: 2;
    margin: 23px auto 0 auto;
    margin-right: 10px;
    max-width: 839px;
    padding: 40.5px 25px 25px 25px;
    position: relative;
    text-align: center;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-self: stretch;

    &.error {
      margin-top: 72px;

      .navigation-container {
        top: -100px;

        @media screen and (max-width: 1216px) {
          top: -125px;
        }
      }

      @media screen and (max-width: 1216px) {
        margin-top: 100px;
      }
    }

    .caveat {
      font-size: 12px;
      font-style: oblique;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 12px;
      margin-top: 8px;
    }

    .check {
      vertical-align: middle !important;

      & ~ label {
        vertical-align: middle !important;
      }
    }

    .clear-all-button {
      position: absolute;
      right: 0;
      top: 0;
    }

    .title {
      color: #122048;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 41px;

      .highlighted {
        color: #3db3e2;
        font-weight: bold;
      }
    }

    .locked-icon {
      cursor: pointer;
    }

    @media screen and (min-width: 1440px) {
      min-width: 840px;
    }
  }

  .button {
    &.is-primary {
      background: rgba(139, 211, 237, 0.25) !important;
    }
  }

  .draggables-container {
    // background: rgba(139, 211, 237, 0.4);
    background-color: #ffffff;
    border: 1px solid #e5e9f2;
    height: 460px;
    max-height: 100%;
    max-width: 300px;
    min-width: 280px;
    margin: 23px auto;
    padding: 10px 40px;
    position: sticky;
    text-align: center;
    top: 10px;

    .actions-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      text-align: right;

      .input {
        max-width: 80%;
      }
    }

    .drag-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-bottom: 30px;
      width: 100%;
    }

    .subtitle {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 16px;
    }

    .title {
      font-size: 21px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 29px;
      margin-top: 14px;
      margin-bottom: 10px;
    }
  }

  .error-container {
    bottom: inherit;
    left: 0;
    top: -50px;
    position: absolute;
    width: 100%;

    @media screen and (max-width: 1216px) {
      top: -78px;
    }
  }

  .navigation-container {
    bottom: inherit;
    margin-bottom: 10px;
    top: -50px;
  }

  .team-container {
    background-color: #ffffff;
    border: 1px solid #122048;
    border-radius: 5px;
    box-sizing: border-box;
    // height: 356px;
    margin: 35px auto 10px auto;
    // max-width: 504px;
    padding: 30px;
    position: relative;
    width: 100%;

    &.active {
      border: 1px solid #3cb3e2;
    }

    .collapsible-toggler {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .team-name {
      background: white;
      color: #122048;
      font-size: 16px;
      font-weight: 900;
      left: 30px;
      letter-spacing: 0.36px;
      line-height: 22px;
      padding: 10px 20px;
      position: absolute;
      top: -20px;
    }

    .teams-container {
      display: flex;
      // justify-content: center;
    }

    .team-item {
      border: 1px solid #e5e9f2;
      border-radius: 5px;
      margin: 0 10px;
      max-width: 250px;
      min-width: 250px;
      // padding-bottom: 60px;

      .team-item__header {
        background-color: #3db3e2;
        border-radius: 5px 5px 0 0;
        box-sizing: border-box;
        font-size: 14px;
        margin-bottom: 0;
        width: 100%;

        .subtitle {
          align-items: center;
          background: white;
          border-bottom: 1px solid #e5e9f2;
          color: #3db3e2;
          display: flex;
          font-size: 14px;
          font-weight: 900;
          justify-content: space-between;
          margin: 0;
          margin-bottom: 11px;
          padding: 6px 10px;
        }

        .title {
          align-items: center;
          color: white;
          display: flex;
          font-size: 14px;
          margin-bottom: 0;
          justify-content: space-between;
          padding: 0 10px;
        }
      }

      &__message {
        color: #7a7a7a;
        margin: 16px;
      }
    }

    .unit {
      &-available-section {
        background-color: #ffffff;
        border: 1px dashed #c1c5c9;
        border-radius: 3px;
        box-sizing: border-box;
        color: #c1c5c9;
        font-size: 12px;
        font-weight: 300;
        height: 34px;
        letter-spacing: 0;
        line-height: 16px;
        margin: 8px auto;
        padding: 7px 0;
        text-align: center;
        width: 85px;
      }
    }
  }
}

.schedule-draggable {
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  height: 32px;
  justify-content: center;
  max-width: 198px;
  min-width: 149px;
  padding: 6px 4px;
  position: relative;
  text-align: left;
  width: 100%;

  &__icons {
    left: 0;
    position: absolute;
    top: -8px;

    img {
      height: 12px;
    }
  }
  
  &__additional-info {
    p {
      color: #122048;
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  &__preview {
    background-color: #ffffff;
    border: 1px solid #122048;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    margin: 8px auto;
    max-width: 198px;
    min-width: 149px;
    padding: 6px 4px;
    position: relative;
    text-align: left;
    transition: all ease-in-out .25s;
    width: 100%;

    &:hover {
      padding: 10px 4px;

      .earliest-date {
        display: block;
      }
    }

    &.disabled {
      opacity: .5;
    }

    &.scheduled {
      width: 80%;
    }

    &~.schedule-lock-container {
      display: inline-block;
      margin-left: 5%;
      width: 10%;
    }

    .earliest-date {
      display: none;
    }
  }

  &__divider {
    background: #122048;
    display: inline-block;
    height: 100%;
    margin: 0 12px;
    width: 1px;
  }

  &__title {
    min-width: 48px;
    text-align: center;
  }

  span {
    left: 0;
    position: relative;
    // top: 50%;
    // transform: translate(-50%, -50%);
  }
}
