.summary-container {
  background-color: #f8f9fa !important;
  padding: 1.5rem 3rem;
}

.summary-table {
  background-color: #f8f9fa !important;
  tr {
    background: transparent;
  }
  td {
    border: none !important;
  }
}

.invoice-summary {
  margin: 15px 40px 0;
  background-color: #f8f9fa;
  padding: 28px 45px;

  &__table {
    background: transparent !important;

    tr {
      background: transparent;
    }
    td {
      border: none !important;
    }
  }
}

.invoice-summary__table-line {
  border-top: double 1px; 
  width: 229px;
  height: 2px;
  margin-right: 0px;
  display: block;
  color: #C1C5C9;
}


