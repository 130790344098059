.view-user {
  .input-set {
    margin-bottom: 10px;
  }
  .input-field {
    input {
      &.form-control {
        width: 100%;
        box-sizing: border-box;
        height: 35px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        padding: 13px 10px;
        outline: none;
        box-shadow: none;
        font-family: Avenir;
        &:hover {
          border-color: #b5b5b5;
        }
        &:focus {
          border-color: #b5b5b5;
        }
      }
      &::placeholder {
        color: #8e8e8e !important;
        opacity: 1;
      }
      ::–webkit-input-placeholder {
        color: #8e8e8e !important;
        opacity: 1;
      }
    }
    select {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      font-size: 14px;
      padding: 10px 10px;
      outline: none;
      &:focus {
        border-color: #3db3e2;
      }
    }
    .css-14el2xx-placeholder {
      font-size: 14px;
    }
    .css-1s2u09g-control {
      border-color: #e5e9f2 !important;
    }
    .react-datepicker__input-container {
      input {
        font-size: 14px;
        &::placeholder {
          color: #122048 !important;
          opacity: 1;
        }
      }
    }
    .unit-select {
      margin-bottom: 8px;
    }
    .checkbox-inner {
      z-index: 0;
    }
    .checkbox-label {
      color: #7a7a7a !important;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }
  }
  .required-star {
    color: red;
  }
  .password-label {
    padding-left: 12px;
    font-weight: 600;
    vertical-align: text-bottom;
    cursor: pointer;
  }
  .button-wrapper {
    //margin-top: 15px !important;
    display: flex;
    justify-content: center;
  }
  .permission-container {
    max-height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .permission-set-helper-container {
    display: flex;
    justify-content: space-between;
  }
  .permission-label {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    font-weight: bold;
  }
  .permission-label-text {
    font-size: 13px !important;
  }
  .permission-bg {
    background-color: #f8f9fa;
  }
  .permission-set {
    padding: 10px;
  }
  .permission-field-set {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    .permission-field {
      width: 32%;
    }
  }
  .link {
    color: #3db3e2;
    text-decoration-line: underline;
    line-height: 15px;
    cursor: pointer;
  }

  .label {
    //margin-top: 7px !important;
    font-weight: normal !important;
    font-size: 14px;
  }

  .password-error {
    padding-left: 13px;
  }
  .error-button {
    margin-bottom: auto;
  }
  .mb-0 {
    margin-bottom: 0px !important;
  }
  .user-permission-container {
    padding: 10px 12px 0px 12px;
    margin-bottom: 8px !important;
    border: 1px solid #e5e9f2;
  }
  .label-heading {
    font-weight: 600;
  }
  .error-top-spacing {
    margin-top: -9px !important;
  }
  .permission-delete {
    cursor: pointer;
    margin-right: -8px !important;
  }
  .button-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
  .m-0 {
    margin: 0px !important;
  }
  .p-0 {
    padding: 0px !important;
  }
  .pb-0 {
    padding-bottom: 0px !important;
  }
  .pt-7 {
    padding-top: 7px !important;
  }
  .mb-8 {
    margin-bottom: 8px !important;
  }
  .mt-8 {
    margin-top: 8px !important;
  }
  .mt-n5 {
    margin-top: -5px !important;
  }
  .ml-8 {
    margin-left: 8px !important;
  }
  .ml-17 {
    margin-left: 17px !important;
  }
  .mt-n20 {
    margin-top: -20px !important;
  }
  .toggle-container {
    display: flex;
    .toggle-control {
      input:checked ~ .control {
        background-color: #3cb3e2;
        &:after {
          left: 25px;
        }
      }
      .control {
        width: 50px !important;
        height: 25px !important;
      }
    }

    .toggle-control .control:after {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: white;
      transition: left 0.15s ease-in;
    }

    .block-text {
      margin-left: 0px;
      margin-right: 15px;
      font-style: normal;
      font-family: Avenir;
      color: #122048;
    }
    .active-text {
      margin-left: 15px;
      font-style: normal;
      font-family: Avenir;
      color: #122048;
    }
  }
  .secondary-card-space {
    margin-top: 10px;
    margin-bottom: -2px !important;
    margin-left: 5px;
    margin-right: 5px;
  }
  .user-account-heading {
    font-size: 20px;
    font-family: Avenir;
    color: #122048;
    font-weight: bold;
  }
  .user-pass-desc {
    font-size: 12px;
    font-family: Avenir;
    color: #122048;
  }
  .user-pass-container {
    display: flex;
    align-items: center;
    .user-generate-container {
      display: flex;
      align-items: center;
    }
  }
  .pass-label {
    margin-top: 7px !important;
    font-weight: normal !important;
  }
  .password-button-wrapper {
    display: flex;
    justify-content: end;
  }
  .password-action-buttons {
    margin: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border: none;
  }
  .permission-rule-container {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .note-container {
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 2px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  label {
    font-size: 14px;
  }
  .pill {
    font-size: 12px;
    padding: 0.4em 0.8em;
    margin: 0.25em;
    border-radius: 1em;
    border: none;
    outline: none;
    background-color: #f8f9fa;
  }
  .content-spacing {
    align-items: baseline;
  }
  .button-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
  .checkbox {
    margin-right: 0px;
  }
  .password-seperator {
    margin-left: 8px;
    margin-right: 8px;
  }
  .loading-icon {
    color: #3cb3e2 !important;
    margin-top: -4px;
  }
}
