.vendor-login-list-container {
  height: auto !important;
  background-color: #ffffff;
  border-radius: 3px;
  min-height: auto !important;
  margin-top: 10px;

  .detail {
    height: 0;
    overflow: hidden;
    background-color: #ffffff !important;
  }
  .open {
    height: max-content !important;
    overflow: visible;
    background-color: #ffffff !important;
  }
  .data-table-wrapper {
    background-color: #ffffff !important;
    min-height: auto !important;
    .data-table-header {
      width: 100%;
      //padding: 24px;
      background-color: #ffffff;
      .header {
        margin-bottom: 0px !important;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .sub-header {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}
