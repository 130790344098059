.add-vendor-login-modal {
  font-family: Avenir;
  color: #122048;
  p {
    &.modal-sync-title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
    }
  }
  .modal-card {
    overflow: initial !important;
    .modal-card-body {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .field-container {
    margin-top: -10px;
    .field-text {
      margin-bottom: 8px;
      .label {
        font-size: 16px;
        font-weight: normal;
      }
    }
    .field-input {
      input {
        width: 100%;
        box-sizing: border-box;
        height: 35px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        padding: 13px 10px;
        outline: none;
        box-shadow: none;
        font-family: Avenir;
        &:hover {
          border-color: #b5b5b5;
        }
        &:focus {
          border-color: #b5b5b5;
        }
        &::placeholder {
          color: #8e8e8e !important;
          opacity: 1;
        }
        ::–webkit-input-placeholder {
          color: #8e8e8e !important;
          opacity: 1;
        }
      }
    }
  }
  .email-exists-container {
    margin-top: -10px;
    .email-exists-message {
      font-size: 15px;
    }
    .email-exists-sub-message {
      font-size: 15px;
      margin-top: 15px;
    }
  }
  .vendor-toggle-container {
    margin-top: -10px;
    margin-bottom: 4rem !important;
    .vendor-toggle {
      font-size: 15px;
      display: flex;
      gap: 1rem;
    }
  }
}

.vendor-toggle-control {
  .toggle-control {
    margin-bottom: 0px;
    input:checked ~ .control {
      background-color: #3cb3e2;
      &:after {
        left: 24px;
      }
    }
    .control {
      width: 50px !important;
      height: 25px !important;
    }
  }

  .toggle-control .control:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: white;
    transition: left 0.15s ease-in;
  }
}

.error-message-mb {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}
