.qc-job-detail {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 
    'header'
    'main';

  &-content {
    grid-area: main;
    overflow: auto;
    background: #FFFFFF;
  }

  &-notes-container {
    margin-left: 8px;
    margin-right: 8px;
  }

  &-loading-notes {
    color: #3DB3E2;
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
  }

  &-header {
    background: #FFFFFF;
    border-bottom: 1px solid #E5E9F2;
    display: flex;
    padding-right: 64px;
    align-items: center;
    
    &-back {
      margin: 16px;
      width: 48px;
    }

    &-title {
      color: #0d264f;
      font-size: 18px;
      font-weight: 300;
      flex: 1;
      text-align: center;
    }
  }

  .title {
    color: #0d264f;
  }

  .level {
    display: flex;
    width: 100%;
    padding: 5px 10px;

    &-left,
    &-right {
      display: flex;
    }
  }
}

.unitspace-reject-dialog {
  position: relative;
  border-radius: 4px;

  .delete {
    position: absolute;
    top: 0;
    right: 0;
  }
  .reason-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .select {
    width: max-content;
  }
}

.unit-confirm-complete-dialog {
  position: relative;
  border-radius: 4px;

  .delete {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-title {
    margin-bottom: 16px;
  }

  &-subtitle {
    margin-bottom: 16px;
  }

  &-question {
    margin-bottom: 16px;
  }
}


.qc-job-unit-detail {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid #E5E9F2;
  padding-top: 16px;
  padding-bottom: 16px;

  &-unit {
    color: #0d264f;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
    padding-left: 16px;
  }

  &-description {
    padding-left: 16px;
    padding-right: 16px;
    color: #0d264f;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
  }

  &-status {
    color: #0d264f;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 16px;
  }

  &-bold {
    font-weight: 600;
  }

  &-border {
    border-top: 1px solid #E5E9F2;
  }

  &-action {
    padding: 0 0 0 8px;
    margin: 0;
    border-width: 0;
  }
}

.order-complete-title {
  color: #122048;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}