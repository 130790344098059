div.manage-reason {
  padding-right: 13px !important;
  padding-bottom: 3px !important;
}

.button-bar {
  //padding: 0 8px !important;
}

.button-option {
  //margin: 16px 0;
  padding: 0 !important;

  &:first-of-type {
    .button {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:last-of-type {
    .button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .button {
    background-color: #f8f9fa;
    box-shadow: 1px 2px 4px 0 #bbbdbf;
    border: 0 !important;
    color: #7a7a7a;

    &.selected {
      background-color: #1384c1;
      color: #ffffff;

      &:hover {
        background-color: lighten(#1384c1, 10);
      }
    }
  }

  .button-container {
    background-color: #f8f9fa;
    box-shadow: 1px 2px 4px 0 #bbbdbf;
    border: 0 !important;
    border-radius: 4px;
    color: #7a7a7a;
    line-height: 24px;

    &:first-of-type {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-of-type {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &:hover {
      background-color: darken(#f8f9fa, 10);
    }

    &.selected {
      background-color: #1384c1;
      color: #ffffff;

      &:hover {
        background-color: lighten(#1384c1, 10);
      }
    }
  }

  // .is-accept {
  //   background-color: #1384c1;

  //   &:hover {
  //     background-color: lighten(#1384c1, 10);
  //   }

  //   &.selected {
  //     background-color:#1384c1;
  //   }
  // }

  // .is-reject {
  //   background-color: #f8f9fa;

  //   &:hover {
  //     background-color: darken(#f8f9fa, 10);
  //   }

  //   &.selected {
  //     background-color: #1384c1;
  //   }
  // }
}

.foot-note {
  color: grey;
  line-height: 1.3rem;
  font-size: 0.8rem;
  margin-bottom: -10px;
}

.modal-card {
  top: 0 !important;
}

.unit-text {
  font-size: 16px;
}

textarea {
  box-sizing: border-box;
  //height: 35px;
  border-radius: 3px;
  border: 1px solid #e5e9f2;
  outline: none;
  box-shadow: none;
  font-family: Avenir;
  &:hover {
    border-color: #b5b5b5;
  }
}
