.button {
  &.is-success {
    font-size: 1rem ;
  }
}

.schedule-all-modal {
  &__text {
    color: #122048;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 22px;
  }
}