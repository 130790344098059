.view-port {
  .is-fullheight {
    height: 94%;
  }
}

.turnboard-container {
  height: calc(100vh - 144px);
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  margin: 40px 40px 20px 40px;
  border-radius: 3px;

  .turnboard-virtualized-list {
    &:focus-visible {
      outline: none !important;
    }
  }

  .turnboard-header-wrapper {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      h2 {
        margin: 0px;
        color: #122048;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.36px;
        line-height: 22px;
        white-space: nowrap;
        margin-right: 10px;
      }

      .toggle-link {
        height: 16px;
        width: 90px;
        color: #3db3e2;
        font-family: Avenir;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 16px;
        white-space: nowrap;
        margin-right: 10px;
        text-decoration: underline;

        &.less-margin {
          margin-right: 5px;
        }
      }

      .center {
        display: flex;
        align-items: center;
        margin-left: 10px;

        &.remove-margin {
          margin-left: unset;
        }

        .question-icon {
          margin-left: 10px;
          cursor: pointer;
        }

        & > button {
          text-transform: capitalize;
          margin-left: 10px;
          border: 1px solid #e5e9f2;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #122048;
          padding: 9px;

          img {
            margin-right: 8px;
            width: 15px;
            height: 15px;
          }
        }
      }

      & > button {
        text-transform: capitalize;
        margin-left: 10px;
        border: 1px solid #e5e9f2;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #122048;
        padding: 9px;

        img {
          margin-right: 8px;
        }
      }

      .turnboard-filter-wrapper {
        .filter-dropdown {
          .dropdown-trigger {
            button {
              height: 30px !important;
              margin-left: 10px !important;
              font-size: 12px;
            }
          }
        }
      }

      .dropdown-action-button {
        margin-left: 10px;
        cursor: pointer;
        position: relative;

        .dropdown {
          .dropdown-trigger {
            button {
              padding: 0px !important;
              border: 0px !important;
            }
          }

          .dropdown-menu {
            // left: 100px !important;

            a {
              color: #122048;
              font-size: 12px;
              padding: 9px 16px !important;
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .toggle-column-input-wrapper {
        margin-left: unset;

        span {
          font-size: 10px;
          white-space: nowrap;
        }

        :nth-child(2) {
          margin-left: 5px;
        }

        .toggle-control {
          padding-left: 45px;
        }
      }
    }

    .custom-icon {
      width: 20px;
      height: 20px;
    }
  }

  .clearBtnWrapper {
    padding: 0px 16px 5px 0px;
    display: flex;
    justify-content: right;
    width: 100%;
    align-items: center;

    button {
      font-size: 12px;
    }
  }

  .applied-filters-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
  }

  .toggle-section {
    display: flex;
    align-items: center;

    .toggle-column-input-wrapper {
      margin-left: unset;

      span {
        font-size: 10px;
        white-space: nowrap;
      }

      :nth-child(2) {
        margin-left: 5px;
      }

      .toggle-control {
        padding-left: 45px;
      }
    }
  }

  .scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .turnboard-table-wrapper {
    width: 100%;
    height: calc(100vh - 215px);
    display: flex;
    overflow: scroll;
    position: relative;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
      height: 7px;
      position: absolute;
      right: 0;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 7px;
      outline: none;
    }

    .is-pointer {
      cursor: pointer;
    }

    .custom-checkbox {
      width: 12px;
      margin: 0;
    }

    .checkbox {
      margin: 0;
    }

    .checkbox-inner {
      z-index: 0;
      width: 12px;
      height: 12px;
      border: 1px solid #122048;
      border-radius: 0;

      &:after {
        height: 6px;
        width: 2px;
        left: 4px;
        top: 1px;
      }
    }

    .checkbox-label {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }

    .is-icon {
      height: 14px;
      width: 14px;
    }

    .turnboard-item-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;

      // &:nth-child(2){
      //   margin-left: 581px;
      // }
      .turnboard-item-title {
        color: #ffffff;
        width: 100%;
        text-align: center;
        padding: 15px 0px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 16px;
        position: relative;

        // border-right: 1px solid #122048 !important;
        span {
          position: absolute;
          right: 20px;
          top: 15px;
          cursor: pointer;
        }

        &.collapsed {
          min-width: 20px;
          text-align: center;

          span {
            position: relative;
            top: 0px;
            right: 0px;
          }
        }
      }

      .first-table {
        .rdt_TableHeadRow {
          padding-left: 0;
        }
      }

      .data-table-wrapper {
        border: 0px !important;
        // border-right: 1px solid #122048 !important;

        .data-table-body {
          // width: 100%;
          // display: grid;
          .rdt_TableCol,
          .rdt_TableCell {
            //min-width: 50px;
            text-align: center;
            padding: 0px 10px;
          }

          .rdt_TableCol {
            padding: 10px;
          }

          .rdt_Table {
            border-color: #122048 !important;
            border-left: 0px !important;
          }
        }
      }

      &.collapsed {
        .data-table-container {
          border-right: 1px solid #122048;
          height: 100%;
        }
      }
    }
  }
}

.symbols {
  padding: 15px;
  box-sizing: border-box;

  .symbol-heading {
    height: 25px;
    width: 240px;
    color: #122048;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 25px;
  }

  .symbol-key-container {
    display: flex;
  }

  .symbol-container {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .symbol-image-container {
      width: 30px;
    }
    .symbol-text-container {
      margin-right: 20px;
    }

    .symbol-box {
      box-sizing: border-box;
      height: 22px;
      width: 84px;
      border: 1px solid #e5e9f2;
      text-align: center;

      &.bg-green {
        background-color: #8dc85c;
      }

      &.bg-white {
        background-color: #ffffff;
      }
    }

    .symbol-text {
      height: 22px;
      margin-left: 15px;
    }
  }
}

.turnboard-container-spacing {
  margin-top: 7px;
}

// data table overrides

.first-table {
  position: sticky;
  left: 0;
  z-index: 99;
}

.table {
  .select {
    z-index: 1;
  }

  .tr {
    &:first-child {
      .th {
        padding: 0px !important;
      }
    }

    .th {
      padding: 10px !important;

      &:first-child {
        max-width: 40px;
      }

      .table-sub-header {
        display: flex;
        align-items: center;
        justify-content: center;

        .sort-icon {
          margin-left: 5px;
        }
      }
    }
  }

  .th {
    color: rgb(18, 32, 72);
    font-family: Avenir;
    font-size: 10px;
    font-weight: 700;

    .header-item {
      text-align: center;
      padding: 15px 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
      color: #ffffff !important;
      position: relative;

      span {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
      }

      &.collapsed {
        min-width: 20px;
        text-align: center;

        span {
          right: 0px;
          top: 0px;
          position: relative;
        }
      }
    }
  }

  .td {
    font-size: 12px;

    &:first-child {
      padding: 10px !important;
      max-width: 40px;
      text-align: center;
    }

    .cell-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      text-transform: capitalize;
    }

    .tags-container {
      text-align: -webkit-center !important;

      .pill {
        border-radius: 10px;
        height: 17px;
        padding: 3px 6px;
        margin-bottom: 0px;
        width: 65px;

        span {
          color: #ffffff;
          font-size: 8px;
          line-height: 11px;
          cursor: pointer;
          font-weight: 700;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }

      .p-absolute {
        position: absolute;
        top: 11px;
        left: 7px;

        &:first-child {
          z-index: 2;
        }

        &:nth-child(2) {
          z-index: 1;
          left: 11px;
          top: 8px;
        }

        &:nth-child(3) {
          z-index: 0;
          left: 13px;
          top: 5px;
        }
      }
    }

    .note-icon {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.toggle-column-input-wrapper {
  position: relative;
  display: flex;
  margin-left: 10px;

  & > span {
    font-size: 10px;
    color: #122048;
    font-weight: 400;
  }
}

.tooltip-container {
  background-color: #ffffff;
  border: 1px solid #e5e9f2 !important;
  box-shadow: 0px 2px 4px 0 #91989e !important;
  min-width: 100px !important;
  padding: 5px;

  p {
    font-size: 13px;
    line-height: 16px;
    color: #122048;
    margin-bottom: 8px;
  }

  .pill {
    display: inline-block;
    border-radius: 6px;
    text-align: center;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  span {
    font-size: 8px;
    line-height: 25px;
    padding: 2px 8px;
    padding-top: -3px !important;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
}
.key-info-legend {
  cursor: pointer;
  span {
    font-size: 12px;
    margin-right: 10px;
  }
  img {
    margin-top: -2px;
  }
}
