.setup-goal-main {
  .pencil-icon-container {
    position: relative;

    .pencil-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
