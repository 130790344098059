.request-rfp-table {
  .is-approved {
    color: #8DC85C;
  }
  table {
    thead {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
          input[type='checkbox'] {
            display: inline-block;
            vertical-align: middle;
          }
          div {
            font-family: Avenir;
            color: #122048;
            font-size: 14px;
            line-height: 19px;
            font-weight: 700;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
          input[type='checkbox'] {
            display: inline-block;
            vertical-align: middle;
          }
          span {
            overflow-wrap: break-word;
            color: #122048;
            font-size: 14px;
            font-family: Avenir;
          }
          &:nth-child(5) {
            span {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
