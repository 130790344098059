.template-manage-dnd {
  &__spaces,
  &__details {
    background-color: #ffffff;
    border: 1px solid #e5e9f2;
    border-radius: 3px;
    box-sizing: border-box;
    flex-grow: 2;
    min-height: 154px;
    padding: 16.5px !important;
    position: relative;
    width: 100%;

    .input-fields {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 47%;
      }
    }
    
    .edit-field__input {
      color: #122048;
      font-size: 16px;
      font-weight: 300;
      height: 31px;
      letter-spacing: 0.36px;
      line-height: 22px;
      padding: 0;
      width: 100%;
    }
  }

  &__details {
    position: sticky;
    top: 0;

    &__header {
      align-items: center;
      display: flex;
      flex-wrap: wrap-reverse;
      justify-content: space-between;
      
      .btn-save {
        width: 127px;
        height: 38px;
      }

      .submenu {
        align-items: center;
        display: flex;


        li {
          border-right: 1px solid#C1C5C9;
          cursor: pointer;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 25px;
          padding: 0 14px;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            border-right: 0;
          }

          &.active {
            color: #3DB3E2;
            font-weight: 900;
          }
        }
      }
    }
  }

  &__spaces {
    padding-bottom: 0 !important;
    position: relative;

    .add-space {
      background-color: #E5E9F2;
      box-sizing: border-box;
      border: 1px solid #E5E9F2;
      cursor: pointer;
      left: 0;
      padding: 5px 0;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .space-container {
      background-color: rgba(139,211,237,0.25);
      box-sizing: border-box;
      padding: 0 16.5px;
      position: relative;

      &.active-space {
        border: 1px solid #0081c3;
      }
      
      &__body {
        background: white;
        margin-left: -16.5px;
        padding: 10px 45px 10px 45px;
        width: calc(100% + 33px);

        &:last-of-type {
          padding-bottom: 0;
        }
      }
      
      &__title {
        padding: 10px 0;
        padding-left: 16.5px;
      }

      &__wrapper {
        max-height: calc(100vh - 260px);
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        &::-webkit-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
      &__draggable {
        width: 100%;
      }

      &__icon {
        margin-right: 12px;
      }
    
      .collapsible-toggler {
        cursor: pointer;
        position: absolute;
        right: 16.5px;
        top: 25px;
      }
    }

    .item-container {
      &__body {
        margin-bottom: 20px;
        padding: 10px 0;
        padding-bottom: 0;

        &:last-of-type {
          margin-bottom: 0;
        }

        .content {
          background: transparentize(#F8F9FA, .75);
          margin-bottom: 0;
        }

        .title {
          color: #0081C3;
          font-size: 16px;
          font-weight: 900;
          display: block;
          letter-spacing: 0;
          line-height: 19px;
          margin-bottom: 10px;
        }
      }

      &__border {
        background: #EEEEEE;
        height: 1px;
        margin-left: -45px;
        margin-top: 16px;
        width: calc(100% + 90px);
      }
    }

    .observation-container {
      &__body {
        padding: 16px 0;
        padding-left: 40px;

        .content {
          background: transparentize(#F8F9FA, .5);
          padding: 10px 0;
        }

        .title {
          font-size: 14px;
        }
      }
    }
    
    .solution-container {
      &__body {
        display: flex;
        flex-wrap: wrap;
        padding-left: 60px;
        position: relative;

        .content {
          background: transparentize(#F8F9FA, .5);
          padding: 10px 0;
        }

        .draggable-item {
          left: 0;
          margin-right: 10px;
        }

        .title {
          font-size: 14px;
        }
      }
    }
    
    .draggable-item {
      background-color: #FFFFFF;
      border: 1px solid #122048;
      border-radius: 3px;
      box-sizing: border-box;
      display: inline-block;
      left: 40px;
      padding: 8px;
      position: relative;
      text-align: left;
      width: 280px;

      &:hover {
        .draggable-item__close {
          display: block;
        }
      }

      &__close {
        background: #122048;
        border-radius: 50px;
        color: white;
        cursor: pointer;
        display: none;
        font-size: 14px;
        font-weight: bold;
        height: 20px;
        line-height: 18px;
        position: absolute;
        right: -8px;
        text-align: center;
        top: -8px;
        width: 20px;
      }
  
      &__icon {
        left: -30px;
        margin-right: 12px;
        position: absolute;
        top: 9px;
      }

      &__preview {
        padding-top: 10px;
      }

      &__instructions {
        display: inline-block;
        padding: 9px 0 16px 0;
      }
    }
  }
}

