.propertyFormModal {

  &__dropdown-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__label {
      width: 25%;
      font-size: 14px;
    }

    &__dropdown {
      width: 75%;
    }
  }
}
