$breakPoint: 1230px;
.view-port {
  .is-fullheight {
    height: 94%;
  }
}
.inspection-list-container {
  height: calc(100vh - 150px);
  //background-color: #ffffff;
  //border: 1px solid #e5e9f2;
  //margin: 40px;
  border-radius: 3px;
  .turnboard-virtualized-list {
    &:focus-visible {
      outline: none !important;
    }
  }
  .turnboard-header-wrapper {
    padding: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    h2 {
      margin: 0px;
      color: #122048;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.36px;
      line-height: 22px;
    }
    & > button {
      text-transform: capitalize;
      margin-left: 10px;
      border: 1px solid #e5e9f2;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #122048;
      padding: 9px;
      img {
        margin-right: 8px;
      }
    }
    .custom-icon {
      width: 20px;
      height: 20px;
    }
    .dropdown-action-button {
      margin-left: 10px;
      cursor: pointer;
      position: relative;

      .dropdown {
        .dropdown-trigger {
          button {
            padding: 0px !important;
            border: 0px !important;
          }
        }
        .dropdown-menu {
          // left: 100px !important;

          a {
            color: #122048;
            font-size: 12px;
            padding: 9px 16px !important;
          }
        }
      }
    }
  }
  .data-table-header {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
      }
      .is-success {
        &:hover {
          background-color: #86c453 !important;
        }
      }
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
      .inspection-action-buttons {
        button {
          margin-right: 12px;
        }
        .is-primary-outlined {
          color: #3db3e2;
          border: 1px solid #3db3e2;
        }
      }
    }
    .more-action-button {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 10px;
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        cursor: pointer;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
    }
  }
  .applied-filters-wrapper {
    display: flex;
    padding: 15px 20px;
  }
  .scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  .turnboard-table-wrapper {
    width: 100%;
    height: calc(100vh - 215px);
    display: flex;
    overflow: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
      height: 7px;
      position: absolute;
      right: 0;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 7px;
      outline: none;
    }
    .is-pointer {
      cursor: pointer;
    }
    .custom-checkbox {
      width: 12px;
      margin: 0;
    }
    .checkbox {
      margin: 0;
    }
    .checkbox-inner {
      z-index: 0;
      width: 12px;
      height: 12px;
      border: 1px solid #122048;
      border-radius: 0;
      &:after {
        height: 6px;
        width: 2px;
        left: 4px;
        top: 1px;
      }
    }
    .checkbox-label {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }
    .is-icon {
      height: 14px;
      width: 14px;
    }
    .turnboard-item-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      // &:nth-child(2){
      //   margin-left: 581px;
      // }
      .turnboard-item-title {
        color: #ffffff;
        width: 100%;
        text-align: center;
        padding: 15px 0px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 16px;
        position: relative;

        // border-right: 1px solid #122048 !important;
        span {
          position: absolute;
          right: 20px;
          top: 15px;
          cursor: pointer;
        }
        &.collapsed {
          min-width: 20px;
          text-align: center;
          span {
            position: relative;
            top: 0px;
            right: 0px;
          }
        }
      }
      .first-table {
        .rdt_TableHeadRow {
          padding-left: 0;
        }
      }
      .data-table-wrapper {
        border: 0px !important;
        // border-right: 1px solid #122048 !important;

        .data-table-body {
          // width: 100%;
          // display: grid;
          .rdt_TableCol,
          .rdt_TableCell {
            min-width: 50px;
            text-align: center;
            padding: 0px 10px;
          }
          .rdt_TableCol {
            padding: 10px;
          }
          .rdt_Table {
            border-color: #122048 !important;
            border-left: 0px !important;
          }
        }
      }
      &.collapsed {
        .data-table-container {
          border-right: 1px solid #122048;
          height: 100%;
        }
      }
    }
  }
}

.inspection-table {
  //width: 100% !important;
  overflow: auto;
  width: -webkit-fill-available;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .inner-header {
    .tr {
      &:first-child {
        display: none !important;
      }
    }
  }
  .tr {
    &:first-child {
      .th {
        padding: 0px !important;
      }
    }
    .th {
      padding: 10px 10px 10px 10px !important;
    }
    &:hover {
      .td {
        &:last-child {
          .menu-item {
            display: block !important;
          }
        }
      }
    }
  }
  .th {
    color: rgb(18, 32, 72);
    font-family: Avenir;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    .table-header {
      display: flex;
      align-items: center;
    }
    select {
      margin-top: 8px !important;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e5e9f2;
      margin: 0px auto;
      outline: none;
      font-size: 12px;
      height: 23px;
    }
    .header-item {
      text-align: center;
      padding: 15px 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
      color: #ffffff !important;
      position: relative;
      span {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
      }
      &.collapsed {
        min-width: 20px;
        text-align: center;
        span {
          right: 0px;
          top: 0px;
          position: relative;
        }
      }
    }
  }
  .td {
    font-size: 12px;
    &:first-child {
      padding: 3px !important;
    }
    &:last-child {
      .menu-item {
        display: none !important;
      }
    }

    .cell-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      //text-align: center;
      font-size: 14px;
      line-height: 15px;
    }
    .menu-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      font-size: 14px;
      line-height: 15px;
    }

    .tags-container {
      text-align: -webkit-center !important;
      .pill {
        border-radius: 10px;
        height: 17px;
        padding: 3px 6px;
        margin-bottom: 0px;
        width: 65px;
        span {
          color: #ffffff;
          font-size: 8px;
          line-height: 11px;
          cursor: pointer;
          font-weight: 700;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
      .p-absolute {
        position: absolute;
        top: 11px;
        left: 7px;

        &:first-child {
          z-index: 4;
        }
        &:nth-child(2) {
          z-index: 3;
          left: 11px;
          top: 8px;
        }
        &:nth-child(3) {
          z-index: 2;
          left: 13px;
          top: 5px;
        }
      }
    }
    .link {
      color: #3db3e2;
      text-decoration-line: underline;
      line-height: 15px;
    }

    .note-icon {
      img {
        width: 20px;
        height: 20px;
        margin-top: -2px;
      }
    }
  }
  .full-header {
    width: 1044px !important;
    display: flex !important;
    @media screen and (min-width: $breakPoint) {
      width: 100% !important;
    }
  }

  .full-body {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    width: 1044px !important;
    overflow: overlay !important;
    @media screen and (min-width: $breakPoint) {
      width: 100% !important;
      overflow: overlay !important;
    }
  }
}
