.is-align-items-center {
  align-items: center !important;
}

.inspection-note-modal {
  font-family: Avenir;
  color: #122048;
  p {
    &.modal-sync-title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
    }
  }
  .modal-card {
    overflow: initial !important;
    .modal-card-body {
      overflow: initial !important;
      padding: 24px 24px;
      h4 {
        color: #122048;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.36px;
        line-height: 22px;
      }
      .is-desktop {
        margin-bottom: 0px;
      }
      .notes-export-section {
        margin-bottom: 8px;
        p {
          color: #122048;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
        }
        button {
          margin-left: auto;
          cursor: pointer;
          color: #122048;
          font-size: 14px;
          font-weight: 300;
          line-height: 19px;
          padding: 0px;
          background-color: transparent;
          border: 0;
          img {
            margin-right: 8px;
            margin-top: -2px;
          }
        }
      }
      // Applying styles to custom class from data-table for wrapping
      .rdt_TableCell div:first-child {
        white-space: pre-wrap;
      }
    }
  }
}




