.qc-work {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 'header' 'main'; }
  .qc-work-content {
    background: #FFFFFF;
    padding: 8px 16px 0px 16px;
    grid-area: main;
    overflow: auto; }
  .qc-work-header {
    margin-bottom: 16px; }
  .qc-work-cards {
    margin-top: 16px; }
