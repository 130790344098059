.invalid {
  border: 1px solid red !important;
  outline: none;
}

.invalid:hover {
  border: 1px solid orange !important;
}

.invalid:focus {
  border: 1px solid orange !important;
}

.invalid-text {
  color: red;
  font-weight: bold;
  font-size: 12px;
}
