.invoice-items {
  &__table {
    border-bottom: 1px solid #e5e9f2;
    margin-bottom: 0 !important;

    thead {
      border-top: 1px solid #e5e9f2;
    }

    td {
      white-space: nowrap;
    }
  }

  &__add-panel {
    padding: 10px 40px;
  }

  &__short-column {
    width: 90px;
  }

  &__shorter-column {
    width: 70px;
  }
}

.item-menu {
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 100;
  top: -0.5rem;
  width: 100%;
  border-radius: 0 0 3px 3px;
  margin-left: 1.5px!important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
