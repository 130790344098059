.mark-as-is-modal {
  .modal-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #122048;
  }

  .modal-desceription {
    margin-top: 15px;
  }

  .modal-confirmation-text {
    margin-top: 18px;
  }

  .modal-actions {
    margin-top: 30px;
  }
}
