@import './forms.scss';
@import './theme.scss';
@import './spacing.scss';
@import './turnable-icon-font.scss';
@import 'bulma';
@import 'bulma-tooltip';

@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  src: url('./fonts/Avenir-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  src: url('./fonts/Avenir-Heavy.ttf') format('truetype');
}

#root {
  font-weight: 300;
  font-family: Avenir, Roboto, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
}

//Custom Helpers
.disabled-events {
  pointer-events: none;
}

.is-pointer {
  cursor: pointer !important;
}

.no-pointer {
  cursor: default !important;
}

.has-radius {
  border-radius: 10px;
}

.is-fullheight {
  //overflow: hidden;
  height: 100%;
}

.is-fullwidth {
  width: 100% !important;
}

.is-flex-1 {
  flex: 1;
}

.center-container {
  display: table;
  margin: 0 auto;
}

.no-wrap {
  white-space: nowrap;
}

.no-break-word {
  word-break: normal;
}

.is-borderless {
  border: none !important;
}

.is-upsidedown {
  transform: rotate(180deg);
}

body {
  background-color: #f8f9fa;
  color: #122149;
}

.has-text-underlined {
  text-decoration: underline;
}

.has-transition-opacity {
  transition: opacity 400ms;
}

.is-size-8 {
  font-size: 0.65rem !important;
}

@media screen and (min-width: 1340px) {
  .is-size-7-widescreen {
    font-size: 0.75rem !important;
  }
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

// text

.is-danger {
  color: #d74d3e;
}

.heading-3 {
  font-size: 24px;
  line-height: 33px;
  font-weight: normal;
}

.heading-4 {
  font-size: 21px;
  line-height: 29px;
  font-weight: normal;
}

.heading-5 {
  font-size: 18px;
  line-height: 25px;
}

.small-text {
  font-size: 12px;
}

.large-text {
  font-size: 16px;
}

.larger-text {
  font-size: 24px;
}

.xl-text {
  font-size: 40px;
}

.no-decoration {
  color: #122149;
}

.no-decoration:hover {
  color: #3db3e2;
}

.main-color {
  color: #3db3e2;
}

.semi-faded-color {
  color: #91989e;
}

.faded-color {
  color: #c1c5c9;
}

.green-color {
  color: #8dc85c;
}

.red-color {
  color: #e94337;
}

.nav-link {
  font-size: 14px;
  line-height: 19px;
}

.is_bottomcentered {
  vertical-align: bottom;
}

.main-button {
  width: 127px !important;
  height: 38px !important;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
}

.text-button {
  background: transparent;
  border: 0;
  color: #3db3e2;
  cursor: pointer;
  vertical-align: middle;

  svg {
    margin-right: 6px;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 1370px) {
  .small-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 1250px) {
  .small-text {
    font-size: 9px;
  }
}

.responsive-table-container {
  overflow-x: auto;
  width: 100%;
}

// bulma overwrites

.modal {
  z-index: 1000;
}

.modal-background {
  background: rgba(229, 233, 242, 0.75);
}

.modal-card-body {
  border: 1px solid #e5e9f2;
  border-radius: 3px;
}

.is-secondary {
  &:disabled {
    color: #122149 !important;
    opacity: 0.5;
  }
}

// calendar input
.DayPickerInput {
  position: relative;
  
  &::after {
    background: url('../../ui/icons/icons/calendar.svg');
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 53%;
    transform: translateY(-50%);
    width: 20px;
  }

  input {
    cursor: pointer;
  }
}
