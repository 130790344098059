.accordion-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #E5E9F2;
  border-left: 0px;
  margin-bottom: 4px;
  .accordion {
    border: none;
    background-color: rgba(139,211,237,0.25);
    color: #444;
    padding: 8px 17px 9px;
    display: flex;
    align-items: center;
    outline: none;
    transition: background-color 0.6s ease;
    border-bottom: 1px solid #E5E9F2;
    svg {
      margin-left: auto;
      cursor: pointer;
    }
    .accordion-title {
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      color: #122048;
      span {
        font-size: 12px;
        color: #91989E;
        font-style: oblique;
        font-weight: 300;
        line-height: 16px;
      }
    }
    .inspection-data {
      h3 {
        color: #0081C3;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0.28px;
        line-height: 19px;
        margin-right: 32px;
        max-width: 164px;
        width: 100%;
      }
    }
    
    
  }
  
  .child-accordion {
    margin-bottom: 0;
    border: 0;
    .accordion-content {
      border-bottom: 1px solid #E5E9F2;
      padding-bottom: 15px;
      .accordion-text {
        margin-left: 221px;
      }
    }
    .accordion {
      background-color: transparent;
      border-color: 0px;
      padding: 8px 17px 0px 24px;
      border-bottom: 0px;
      p {
        color: #91989E;
        font-size: 10px;
        font-weight: 300;
        line-height: 14px;
      }
      .accordion-title {
        color: #91989E;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0.28px;
        line-height: 19px;
        margin-right: 32px;
        max-width: 164px;
        width: 100%;
        text-align: left;
        margin-top: 8px;
      }
      .accordion-title-active {
        color: #0081C3;
      }
      .button {
        max-width: 94px;
        width: 100%;
        border: 1px solid #E5E9F2;
        border-radius: 21.5px;
        background-color: #FFFFFF;
        color: #122048;
        font-size: 11px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        padding: 12px 15px;
        height: 32px;
        margin-right: 8px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .is-good {
        background-color: #8DC85C;
        border-color: #8DC85C;
        color: #ffffff;
        font-weight: 900;
        box-shadow: 0 2px 4px 0 #C1C5C9;
      }
      .is-needs-work {
        background-color: #F1576d;
        border-color: #F1576d;
        color: #ffffff;
        font-weight: 900;
        box-shadow: 0 2px 4px 0 #C1C5C9;
      }
    }
  }

  .accordion-content {
    background-color: #ffffff;
    overflow: hidden;
    transition: max-height 0.6s ease;
    .accordion-text {
      font-weight: 400;
      font-size: 14px;
      padding: 0px;
      display: none;
    }
    .accordion-content-active {
      display: block;
      transition: max-height 0.6s ease;
    }
  }
}

.is-flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
