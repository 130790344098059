$primaryColor: #122048;
$dangerColor: #f1576d;
$secondaryColor: #3db3e2;
$activeColor: #8dc85c;
.pricing-list-container {
  //height: calc(100vh - 282px);
  border: 2px solid #e5e9f2;
  background-color: #ffffff;
  border-radius: 3px;
  .pricing-table-header {
    width: 100%;
    padding: 24px 24px 0px 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 21px;
        line-height: 29px;
        color: #122048;
        margin: 0px;
        font-weight: 400;
      }
      .export {
        font-size: 14px;

        &:hover {
          border-color: #b5b5b5;
        }
        &:focus {
          border-color: #b5b5b5;
        }
        &:active {
          border-color: #b5b5b5;
        }

        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
    }
  }
  .pricing-tab-container {
    width: 100%;
    padding: 5px 24px 24px 24px;
    overflow-x: hidden;
    .tab-option-container {
      height: 35px;
      max-width: 100%;
      padding: 0;
      margin-bottom: 10px;
      button {
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
        line-height: 22px;
        color: #122048;
        background-color: transparent !important;
        margin: 6px 18px 6px 0px;
        display: inline;
        text-transform: capitalize;
        font-size: 16px;
        &:hover {
          color: $secondaryColor;
        }
      }
      .active {
        color: $secondaryColor;
        font-weight: 700;
        border-bottom: 3px solid $secondaryColor;
      }
      .tab-info {
        height: 22px;
        width: 22px;
        margin-top: -3px;
        margin-left: 3px;
      }
    }
    .tab-content-container {
      height: 500px;
      overflow: hidden;
      //width: -webkit-fill-available;
      //overflow-x: hidden;
      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      width: 100%;
      background-color: #ffffff;
      border: 1px solid #e5e9f2;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 #c1c5c9;
      padding: 25px;
      .pricing-table {
        .field-input {
          input {
            width: 100%;
            box-sizing: border-box;
            height: 35px;
            border-radius: 3px;
            border: 1px solid #e5e9f2;
            padding: 13px 10px;
            outline: none;
            box-shadow: none;
            font-family: Avenir;
            &:hover {
              border-color: #b5b5b5;
            }
            &:focus {
              border-color: #b5b5b5;
            }
            &::placeholder {
              color: #8e8e8e !important;
              opacity: 1;
            }
            ::–webkit-input-placeholder {
              color: #8e8e8e !important;
              opacity: 1;
            }
          }
        }
        .th {
          border: 0;
          .pricing-tab-header {
            margin-bottom: 10px;
          }
        }
        .close-button {
          cursor: pointer;
        }
        .add-row {
          display: inline-block;
          cursor: pointer;
          span {
            font-size: 12px;
          }
        }
      }

      table {
        thead {
          tr {
            td {
              padding: 4px 11px;
            }
            th {
              font-size: 15px;
              margin-bottom: 15px;
            }
          }
        }
        tbody {
          tr {
            margin-bottom: 3px;
          }
        }
      }
    }
  }
  .price-input {
    margin-top: 4px !important;
    &:hover {
      border-color: #b5b5b5;
      box-shadow: none;
    }
    &:focus {
      border-color: #b5b5b5;
      box-shadow: none;
    }
    &:active {
      border-color: #b5b5b5;
      box-shadow: none;
    }
    &::placeholder {
      color: #8e8e8e !important;
      opacity: 1;
    }
    ::–webkit-input-placeholder {
      color: #8e8e8e !important;
      opacity: 1;
    }
  }
  .select-wrapper {
    margin-top: -5px;
    height: 25px !important;
    font-size: 11px !important;
    select {
      &:hover {
        border-color: #b5b5b5;
        box-shadow: none;
      }
      &:focus {
        border-color: #b5b5b5;
        box-shadow: none;
      }
      &:active {
        border-color: #b5b5b5;
        box-shadow: none;
      }
      &::placeholder {
        color: #8e8e8e !important;
        opacity: 1;
      }
      ::–webkit-input-placeholder {
        color: #8e8e8e !important;
        opacity: 1;
      }
    }
  }
  .select-inner {
    height: 25px;
    font-size: 10px !important;
  }
  .error-container {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    margin-left: 10px !important;
  }
}

.pricing-quotation {
  font-family: Avenir;
  .mt-30 {
    margin-top: 30px;
  }
  .pricing-top-header {
    margin-top: 6px;
    .pricing-header-container {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 24px;
        line-height: 33px;
        color: $primaryColor;
        font-weight: 700;
      }
    }
  }
}
