.inspection-modal-wrapper {
  width: calc(100% - 275px);
  height: calc(100vh - 64px);
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 100;
  background-color: #4c5674;
  .inspection-modal-header {
    width: 100%;
    background-color: #122048;
    padding: 22px 24px;
    color: #ffffff;
    .columns {
      &:last-child {
        span {
          width: 100%;
          display: inline-block;
          text-align: right;
        }
      }
      p {
        width: 100%;
        display: inline-block;
        text-align: right;
      }
    }

    h4,
    p {
      font-weight: 700;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.36px;
    }
    .action-button {
      cursor: pointer;
    }
    .action-button-close {
      width: 13.71px;
    }
  }
  .pdf-wrapper {
    iframe {
      height: calc(100vh - 138px);
    }
  }
}
