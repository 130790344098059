div.tooltip {
  position: absolute;
  border: 1px solid #d3d3d3;
  background-color: #fff;

  &::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(74, 74, 74, 0.9);
    border-top: 0;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    top: -8px;
    transform: translateX(-50%);
    width: 0;
  }

  &.last {
    &::before {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 0;
      border-top: 8px solid rgba(74, 74, 74, 0.9);
      left: 50%;
      top: 100%;
      width: 0;
      height: 0;
    }
  }

  &.auto {
    transform: translateX(-43.5%);

    &.last {
      transform: translate(-43.5%, -100%);
    }
  }

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
