div.setup-vendors-main {
  div.stackable-box {
    border: 2px solid #eef1f6;
    border-radius: 0;
    margin-bottom: 0 !important;

    &:not(:first-child) {
      border-top: 0;
    }
    &.height-40 {
      min-height: 40px;
      line-height: 40px;
    }
  }
}

.vendor-section {
  .breadcrumb {
    margin-bottom: 15px !important;
  }
  .back-text {
    margin-bottom: 15px;
  }
}
