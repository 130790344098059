$breakPoint: 1230px;
.view-port {
  .is-fullheight {
    height: 94%;
  }
}

.pricing-form-table {
  //width: 100% !important;
  overflow: auto;
  width: -webkit-fill-available;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .tr {
    .th {
      padding: 10px !important;
    }
    &:hover {
      .td {
        &:last-child {
          .menu-item {
            display: block !important;
          }
        }
      }
    }
  }
  .th {
    color: rgb(18, 32, 72);
    font-family: Avenir;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    .table-header {
      display: flex;
      align-items: center;
    }
    select {
      margin-top: 8px !important;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e5e9f2;
      margin: 0px auto;
      outline: none;
      font-size: 12px;
      height: 23px;
    }
    .header-item {
      text-align: center;
      padding: 15px 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
      color: #ffffff !important;
      position: relative;
      span {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
      }
      &.collapsed {
        min-width: 20px;
        text-align: center;
        span {
          right: 0px;
          top: 0px;
          position: relative;
        }
      }
    }
  }
  .td {
    font-size: 12px;
    &:last-child {
      .menu-item {
        display: none !important;
      }
    }

    .cell-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      //text-align: center;
      font-size: 12px;
      line-height: 15px;
      .link {
        color: #3db3e2;
        text-decoration-line: underline;
        line-height: 15px;
      }
    }
    .menu-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      font-size: 14px;
      line-height: 15px;
    }
  }
  .full-header {
    width: 1044px !important;
    display: flex !important;
    @media screen and (min-width: $breakPoint) {
      width: 100% !important;
    }
  }

  .full-body {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    width: 1044px !important;
    overflow: overlay !important;
    @media screen and (min-width: $breakPoint) {
      width: 100% !important;
      overflow: overlay !important;
    }
  }
}
