.menu#sidebar {
  position: fixed;
  top: 64px;
  overflow: auto;
  padding-bottom: 64px;

  .collapser {
    padding-left: 24px;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .option {
    margin-left: 36px;
  }

  .services-option {
    margin-left: 12px;
  }

  .badge {
    position: relative;
    display: inline-block;
    /* Ensures it wraps around its content */
    cursor: pointer;
    border-radius: 14%;
    background-color: #004c99 !important;
    color: #fff;
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
    width: 45px;
    text-align: center;
    // padding: 4px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .tooltip-badge {
    visibility: hidden;
    /* Hidden by default */
    width: 80px;
    background-color: #004c99;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 4px;

    /* Positioning */
    position: absolute;
    bottom: 125%;
    /* Position above the element */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    /* Animation */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .badge:hover .tooltip-badge {
    visibility: visible;
    opacity: 1;
  }

  .down-arrow {
    font-size: 16px;
    margin-top: 8px;
    margin-right: 1.5rem;
  }

  .menu-list li a {
    padding: 0.5em 24px;

    .badge-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #f14668;
      color: #fff;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .menu-icon {
    padding-right: 8px !important;
  }

  .menu-item {
    &--section {
      padding: 10px 0 !important;
      text-transform: uppercase;
    }
  }
  .app-menu-home-icon {
    display: flex;
    padding-right: 9px !important;
  }
  .app-menu-map-icon {
    display: flex;
    padding-right: 18px !important;
  }
  .app-menu-logout-icon {
    display: flex;
    padding-right: 13px !important;
  }
}
