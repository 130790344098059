$grey-lighter: hsl(0, 0%, 93%);

div.progress-card {
  box-shadow: none;
  border: 1px solid $grey-lighter;
  height: 100%;
  max-height: 189px;
  min-height: 189px;

  &.open-sidebar {
    @media screen and (min-width: 1216px) and (max-width: 1400px) {
      max-height: none;
    }
  }
}

.simplebar-custom {
  max-height: 189px;

  &.open-sidebar {
    .simplebar-mask {
      overflow: visible !important;

      @media screen and (min-width: 1216px) and (max-width: 1400px) {
        overflow: hidden !important;
      }
    }

    .simplebar-content-wrapper {
      overflow: visible !important;

      @media screen and (min-width: 1216px) and (max-width: 1400px) {
        overflow: hidden scroll !important;
      }
    }
  }

  .simplebar-mask,
  .simplebar-content-wrapper {
    overflow: visible !important;
  }
}
