.add-charges-modal {
  font-family: Avenir;
  color: #122048;
  p {
    &.modal-sync-title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
    }
  }
  .modal-card {
    overflow: initial !important;
    .modal-card-body {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .form-group {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 5px !important;
    .label {
      padding-top: 23px;
    }
    label {
      font-size: 14px;
      line-height: 19px;
      font-weight: 300;
    }
    .input-field {
      input {
        &.form-control {
          width: 100%;
          box-sizing: border-box;
          height: 45px;
          border-radius: 3px;
          border: 1px solid #e5e9f2;
          padding: 13px 10px;
          outline: none;
          font-family: Avenir;
          &:focus {
            border-color: #3db3e2;
          }
        }
      }
      select {
        width: 100%;
        box-sizing: border-box;
        height: 45px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        font-size: 14px;
        padding: 10px 10px;
        outline: none;
        &:focus {
          border-color: #3db3e2;
        }
      }
      .css-14el2xx-placeholder {
        font-size: 14px;
      }
      .css-1s2u09g-control {
        border-color: #e5e9f2 !important;
      }
      .react-datepicker__input-container {
        input {
          font-size: 14px;
          &::placeholder {
            color: #122048 !important;
            opacity: 1;
          }
        }
      }
      .unit-select {
        margin-bottom: 8px;
      }
      .checkbox-inner {
        z-index: 0;
      }
      .checkbox-label {
        color: #122048;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
      }
    }
  }

  .note-field-spacing {
    margin-top: -18px !important;
  }

  .toggle-field-spacing {
    margin-top: -22px !important;
  }

  .m-b-space {
    margin-top: 20px !important;
  }

  .notification {
    align-items: center;
    padding: 10px 15px;
    font-size: 12px;
    margin-top: 10px;
    button {
      background-color: transparent;
      border: none;
      padding: 0px;
      cursor: pointer;
      margin-left: auto;
    }
  }
}

.toggle-control {
  input:checked ~ .control {
    background-color: #3db3e2;
    &:after {
      left: 20px;
    }
  }
  .control {
    width: 40px !important;
    height: 20px !important;
  }
}

.active-text {
  font-size: 12px;
  color: #91989e;
  font-style: italic;
  margin-left: 50px;
  span {
    font-weight: bold;
  }
}

.general-error {
  font-weight: 600;
  font-size: 12px;
  color: #e93f33 !important;
}

.character-limit {
  color: #91989e;
  font-size: 10px;
  span {
    font-weight: 700;
  }
}
