$breakpoint-wxga: 1280px;
$breakpoint-hd: 1440px;

hr.navbar-divider {
  background-color: #0d264f;
  max-width: 15px;
  margin-top: 9px;
  height: 1px;
}

.inspect-table {
  width: 100%;

  .box-header {
    padding-left: 4px;
    padding-right: 4px;
    max-width: 70px;
    min-width: 70px;
  }

  .boxes {
    max-width: 70px;
    min-width: 70px;
    padding: 0 4px !important;
    white-space: normal !important;

    &-value {
      font-size: 10px;
      line-height: 10px;
      display: inline-block;
      // max-height: 13px;
      max-width: 95%;
      overflow: hidden;
    }

    .full {
      border: 1px solid #0081c3;
      border-radius: 3px;
      box-shadow: rgb(206, 206, 206) 1px 1px 3px 1px;
      background-color: #0081c3;
      color: white;
      height: 20px;
    }
    .empty {
      border: 1px solid rgb(206, 206, 206);
      border-radius: 3px;
      box-shadow: rgb(206, 206, 206) 1px 1px 3px 1px;
      background-color: white;
      height: 20px;
    }

    div {
      margin-left: 0;
      margin-top: 5px;
      max-width: 53px;
    }
  }

  th,
  td {
    font-size: 13.5px;
    min-width: 100px;
    word-break: initial;
  }

  th {
    font-weight: 500;

    &.exception {
      min-width: 110px;

      span {
        display: inline-block;
        max-height: 27px;
        max-width: 27px;
      }
    }

    &.keys-returned,
    &.inspection-date &.lease-status {
      min-width: 120px;
    }

    &.space-cost {
      min-width: 90px;
    }
    &.unit-space {
      min-width: 110px;
    }
  }
}

.inspect-card {
  &-header {
    .note-icon {
      height: 20px;
      width: 20px;
    }
  }

  &-footer {
    align-items: center;
    border-bottom: 1px solid #e5e9f2;
    border-top: 1px solid #e5e9f2;
    display: flex;
    justify-content: space-between;

    .total {
      padding-right: 10.125px;

      span {
        font-weight: 500;
      }
    }
  }
}

.details {
  &-container {
    height: auto;
    &.closed {
      height: 0;
      overflow: hidden;
      padding: 0 !important;
    }
  }
}

@media (max-width: $breakpoint-hd) {
  .inspect-table {
    .boxes {
      width: 6rem !important;
    }
  }
}

@media (max-width: $breakpoint-wxga) {
  .inspect-table {
    .boxes {
      width: 5rem !important;
      white-space: normal !important;
    }
  }
}
