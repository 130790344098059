.turnboard-all-tags-modal {
  .is-disabled {
    cursor: not-allowed !important;
  }
  .modal-sync-title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #122048;
  }
  .is-pointer {
    cursor: pointer;
  }
  h4 {
    color: #122048;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 22px;
    margin-bottom: 16px;
  }
  .notification {
    align-items: center;
    padding: 10px 15px !important;
    font-size: 12px;
    margin-top: 0px;
    button {
        background-color: transparent;
        border: none;
        padding: 0px;
        cursor: pointer;
        margin-left: auto;
    }
  }
  .no-tags {
    font-size: 10px;
    line-height: 14px;
  }
  .modal-card {
    overflow: initial !important;
    .modal-card-body {
      overflow: initial !important;
      padding: 24px 24px;
      h4 {
        color: #122048;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.36px;
        line-height: 22px;
      }
      .notification {
        align-items: center;
        padding: 10px 15px !important;
        font-size: 12px;
        margin-top: 0px;
        button {
            background-color: transparent;
            border: none;
            padding: 0px;
            cursor: pointer;
            margin-left: auto;
        }
      }
    }
  }
  .selected-tags-container {
    margin-bottom: 16px;
    h5 {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        margin-bottom: 8px;
    }
    .pill-container {
      display: flex;
      flex-wrap: wrap;
      max-height: 100px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    .pill {
      border-radius: 7px;
      margin-right: 8px;
      height: 22px;
      padding: 4px 8px;
      margin-bottom: 6px;
      span {
        color: #ffffff;
        font-size: 10px;
        line-height: 14px;
        vertical-align: top;
        font-weight: 700;
        text-transform: uppercase;
      }
      img {
        margin-left: -4px;
        margin-top: -17px;
        cursor: pointer;
      }
    }
  }
  .available-tags-container {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #E5E9F2;
    h5 {
        color: #122048;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .pill-container {
      display: flex;
      flex-wrap: wrap;
      max-height: 100px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .pill {
        border-radius: 7px;
        margin-right: 3px;
        height: 19px;
        padding: 3px 7px;
        margin-bottom: 3px;
        span {
          color: #ffffff;
          font-size: 10px;
          line-height: 14px;
          vertical-align: top;
          cursor: pointer;
          text-transform: uppercase;
        }
        
      }
    }
  }
  .new-tags-container {
    margin-bottom: 43px;
    color: #122048;
    h5 {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .main-container {
      display: flex;
      justify-content: space-between;
      .input-container {
        display: flex;
        align-items: center !important;
        .new-tag-input {
          height: 27px;
          width: 129px;
          border-radius: 12.5px;
          border: 1px solid #E5E9F2;
          padding: 6.5px 12.5px;
          font-size: 10px;
          line-height: 14px;
          outline: 0;
          margin-right: 7.5px;
          background-color: #F8F9FA;
        }
        p {
          font-size: 10px;
          line-height: 14px;
          margin-right: 7.5px;
          vertical-align: middle;
        }
        span {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 3px;
        }
        .is-selected-color {
          width: 19px !important;
          height: 19px !important;
        }
      }
      .action-container {
        display: flex;
        align-items: center !important;
        p {
          font-size: 12px;
          margin-right: 12px;
          color: #3DB3E2;
          line-height: 16px;
          text-decoration: underline;
          cursor: pointer;
        }
        button {
          font-size: 12px;
          color: #FFFFFF;
          line-height: 16px;
          font-weight: 100;
        }
      }
    }
  }
}




