.toggler {
  background: transparent;
  border: 0;
  color: #021d49;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;

  * {
    vertical-align: middle;
  }

  &:focus {
    outline: none;
  }
}
