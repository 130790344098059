.qc-search {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 'header' 'main'; }
  .qc-search-content {
    grid-area: main;
    overflow: auto;
    background-color: #FFFFFF; }
  .qc-search-box {
    box-shadow: 0 2px 4px 0 #E5E9F2;
    padding: 16px 16px 32px 16px; }
  .qc-search-input {
    border: 0;
    border-bottom: 1px solid #000;
    padding-bottom: 8px;
    padding-top: 8px; }
    .qc-search-input::-webkit-input-placeholder {
      font-size: 20px; }
  .qc-search-result-title {
    color: #3DB3E2;
    font-size: 14px;
    font-weight: 900;
    padding: 24px 24px 24px 24px;
    border-bottom: 1px solid #E5E9F2; }
  .qc-search-result-item {
    color: #122048;
    font-size: 16px;
    font-weight: 300;
    padding: 21px 21px 21px 21px;
    border-bottom: 1px solid #E5E9F2; }
  .qc-search-empty {
    height: calc(100vh - 315px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
