$fontColor: #122048;
$borderColor: #e5e9f2;
$primaryColor: #3db3e2;

.service-tabs-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  font-family: Avenir;
  ul.tab-options {
    li {
      border-color: $borderColor;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 19px;
      color: $fontColor;
      &.active,
      &:hover {
        color: #ffffff;
      }
      &.active {
        font-weight: 700;
      }
    }
  }
}

.service-tabs-body {
  width: 100%;
  min-height: 200px;
  height: auto;
  background-color: #ffffff;
  border: 2px solid $borderColor;
  border-radius: 3px;
  position: relative;
  top: 32px;
  padding: 25px;
  font-family: Avenir;
  .service-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 21px;
      line-height: 29px;
      color: $fontColor;
      margin: 0px;
      font-weight: 400;
    }
    button {
      font-size: 16px;
      min-width: 120px;
      font-weight: 300;
      line-height: 22px;
      &:first-child {
        margin-right: 10px;
        background: transparent !important;
        color: $primaryColor;
        &:hover {
          color: $primaryColor;
          box-shadow: 0 0 0 0.125em rgba(60, 179, 226, 0.25);
        }
        &:focus {
          color: $primaryColor;
          box-shadow: none;
        }
      }
    }
    button.manual-button {
      background: $primaryColor !important;
      color: #ffffff;
      &:hover {
        color: #ffffff;
        box-shadow: 0 0 0 0.125em rgba(60, 179, 226, 0.25);
      }
      &:focus {
        color: #ffffff;
        box-shadow: none;
      }
    }
  }
  p {
    margin-top: 15px;
    max-width: 70%;
    font-family: Avenir;
    font-size: 12px;
    line-height: 16px;
  }
  .service-detail-body {
    margin-top: 35px;
    font-family: Avenir;
    .pricing-tabs-wrapper {
      position: relative;
      width: 100%;
      z-index: 1;
      .pricing-tabs-header {
        .tab-options-container {
          position: relative;
        }
        ul.tab-options {
          margin-left: 0px;
          li {
            text-transform: uppercase;
            line-height: 22px;
            color: $fontColor;
            background-color: transparent !important;
            border: 0px;
            margin: 6px 16px 6px 0px;
            padding: 0px;
            display: inline;
            text-transform: capitalize;
            font-weight: 300;
            font-size: 16px;
            position: relative;
            &.active,
            &:hover {
              color: $primaryColor;
            }
            &.active {
              border-bottom: 2px solid $primaryColor;
              font-weight: 600;
            }
          }
        }
      }
      .pricing-tabs-content {
        width: 100%;
        background-color: #ffffff;
        border: 1px solid $borderColor;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 #c1c5c9;
        min-height: 400px;
        position: relative;
        font-family: Avenir;
        // top: 45px;
        padding: 25px;
        .h-gap-30 {
          margin-left: 30px;
        }
        .input-field {
          input {
            width: 100%;
            background-color: #ffffff;
            border: 1px solid $borderColor;
            height: 35px;
            padding: 8px;
            &:focus {
              border: 1px solid $primaryColor;
              box-shadow: 0 2px 4px 0 #c1c5c9;
              outline: $primaryColor;
            }
          }
        }
        .sub-pricing-section {
          .capacity-date-info {
            h3 {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              color: $fontColor;
              margin-right: 68px;
              span {
                color: $primaryColor;
              }
            }
            p {
              font-size: 14px;
              line-height: 19px;
              max-width: 100%;
              color: $fontColor;
              margin-bottom: 24px;
            }
          }
          h2 {
            font-size: 14px;
            line-height: 19px;
            color: $fontColor;
          }
          p {
            color: $fontColor;
            font-size: 14px;
            line-height: 19px;
          }
          table {
            th {
              font-weight: 700;
              padding-bottom: 15px;
              border: 0;
            }
            .columns {
              &:last-child {
                margin-bottom: 0;
              }
            }
            td {
              padding: 4px 11px;
              border: 0;
            }
            p {
              margin-top: 0;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
.business-information-wrapper {
  padding-top: 25px;
  .input-field {
    input {
      width: 100%;
      background-color: #ffffff;
      border: 1px solid $borderColor;
      height: 35px;
      padding: 8px;
      border-radius: 3px;
      &:focus {
        border: 1px solid $primaryColor;
        box-shadow: 0 2px 4px 0 #c1c5c9;
        outline: $primaryColor;
      }
    }
    p {
      color: $fontColor;
      font-family: Avenir;
      font-size: 14px;
      line-height: 19px;
      max-width: none;
      margin: 8px 0px 0px 0px;
    }
    label {
      color: $fontColor;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 15px;
      display: block;
    }
    textarea {
      min-height: 156px;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid $borderColor;
      padding: 8px;
      border-radius: 3px;
      resize: none;
      &:focus {
        border: 1px solid $primaryColor;
        box-shadow: 0 2px 4px 0 #c1c5c9;
        outline: $primaryColor;
      }
    }
    select {
      border-radius: 3px;
    }
    .w-83 {
      max-width: 83px;
    }
    .w-110 {
      max-width: 110.5px;
      margin-left: 3px;
    }
    .w-68 {
      max-width: 68px;
      margin-left: 3px;
    }
    .w-50 {
      width: 50%;
      select {
        width: 100%;
      }
    }
    .address-fields {
      input {
        margin-bottom: 3px;
      }
    }
    .column {
      .columns {
        .column {
          padding-top: 0px;
          padding-bottom: 8px;
        }
      }
    }
    .note-field {
      padding-top: 0px;
    }
  }
  .view-state {
    p {
      margin-top: 0px;
    }
    span {
      margin-right: 3px;
    }
  }
}
.review-submit-wrapper {
  font-family: Avenir;
  position: relative;
  .service-tabs-body {
    margin-bottom: 7px;
    border: 1px solid $borderColor;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 #c1c5c9;
  }
  .review-submit-heading {
    h2 {
      color: $fontColor;
      font-size: 21px;
      line-height: 29px;
      margin-bottom: 16px;
    }
    p {
      color: $fontColor;
      font-size: 12px;
      line-height: 16px;
      max-width: 70%;
      margin-bottom: 0px;
      margin-top: 4px;
    }
    button {
      margin-left: auto;
      width: 127px;
    }
  }
  h4 {
    color: $fontColor;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
    font-weight: 700;
  }
  p {
    color: $fontColor;
    font-size: 14px;
    line-height: 19px;
    margin: 0px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 16px;
    }
  }
  h3 {
    color: $primaryColor;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.36px;
    line-height: 22px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  .edit-service-button {
    position: absolute;
    top: 24px;
    right: 24px;
    border: 0;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
  }
  .sub-services-list {
    p {
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
  .business-information-list {
    h4 {
      margin-bottom: 8px;
    }
    .m-0 {
      margin: 0px;
    }
  }
}
