$breakpoint: 1327px;

.dashboard-card {
  box-shadow: none;
  border: 1px solid #ededed;
  background-color: white;
  padding: 0;
  position: relative;
  border-radius: 3px;
}

.card-xs {
  height: 7rem;
}

.card-sm {
  height: 8.1rem;
}

.card-sm-semi {
  height: 9.1rem;
}

.card-semi {
  height: 10.1rem;
}

.card-md {
  height: 11.2rem;
}

.card-fill {
  height: 100%;
}

.strongest {
  font-weight: 700;
}

.semistrong {
  font-weight: 400;
}

.dashboard-message {
  border-radius: 0% !important;
  background-color: #e5e9f2;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
}

.with-divider:after {
  content: '';
  height: 2.6rem;
  top: 1.2rem;
  left: 50%;
  width: 1px;
  background: #ecedee;
  position: absolute;
}

.dark-background {
  background-color: #122048;
  color: white;
  padding: 0 !important;
}

.light-background {
  background-color: #3db3e2;
  color: white;
  padding: 0 !important;
}

.vertical-center {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.inner-content {
  padding: 16px;
  width: 100%;
  height: 100%;
}

.standard-padding {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.dashboard-stars {
  li {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    &.is-active {
      svg {
        color: #3db3e2 !important;
      }
    }
  }
}

.small-spacing {
  padding: 0.3rem 0.75rem !important;
}

.clustered {
  padding: 0.1rem !important;
}

.with-ellipsis {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bottom-separator:not(:last-child) {
  border-bottom: 2px solid rgba(193, 197, 201, 0.2);
  margin-bottom: 16px;
}
