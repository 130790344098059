.setup-users-manage {
  .box {
    border: 1px solid #e5e9f2;
    border-radius: 3px;
    padding: 1.25rem 1.25rem 24px 1.25rem;
    .circle-image {
      object-fit: cover;
      border-radius: 50%;
      height: 86px;
      width: 86px;
      margin-bottom: 15px;
      margin-top: 8px;
    }
  }

  .user-title {
    color: #122048;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 33px;
    text-transform: capitalize;
  }
}
