.vendor-company-list-container {
  height: calc(100vh - 200px);
  //background-color: #122048 !important;
  border-radius: 3px;
  .data-table-header {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        margin-right: 12px;
      }
      .is-success {
        &:hover {
          background-color: #86c453 !important;
        }
      }
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
      .vendor-action-buttons {
        button {
          margin-right: 12px;
        }
        .is-primary-outlined {
          color: #3db3e2;
          border: 1px solid #3db3e2;
        }
      }
    }
  }
}
