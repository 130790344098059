.isDisabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed !important;
}

.disable-tooltip {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.customTooltipTheme {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0px 0 1px rgba(10, 10, 10, 0.02);
  background-color: #fff !important;
  color: #26323d !important;
  opacity: 1 !important;

  &.place-bottom {
    &:after {
      border-bottom-color: #fff !important;
    }
  }

  &.place-top {
    &:after {
      border-top-color: #fff !important;
    }
  }

  &.place-left {
    &:after {
      border-left-color: #fff !important;
    }
  }

  &.place-right {
    &:after {
      border-right-color: #fff !important;
    }
  }
 }

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end{
  justify-content: end;
}

.align-items-center {
  align-items: center;
}
