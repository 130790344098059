.in-house-modal {
  .modal-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #122048;

    .heading-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        cursor: pointer;
        font-size: 12px;
        font-weight: 300;
        line-height: 22px;
      }
      .is-success {
        &:hover {
          background-color: #86c453 !important;
        }
      }
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
      .in-house-action-buttons {
        button {
          margin-left: 12px;
        }
        .is-primary-outlined {
          color: #3db3e2;
          border: 1px solid #3db3e2;
        }
      }
    }
  }

  .data-table-wrapper {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e5e9f2;
    min-height: 200px;
  }

  .modal-desceription {
    margin-top: 15px;
  }

  .modal-confirmation-text {
    margin-top: 18px;
  }

  .modal-actions {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
