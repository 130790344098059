.setup-staff-service-row {
  height: 40px;
  line-height: 40px;
  padding: 0 53px;
  border-top: 1px solid #eef1f6;
}
.setup-staff-add-button {
  margin-top: -3px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
}
div.setup-staff-main {
  div.stackable-box {
    border: 2px solid #eef1f6;
    border-radius: 0;
    margin-bottom: 0 !important;

    &:not(:first-child) {
      border-top: 0;
    }
    &.height-40 {
      min-height: 40px;
      line-height: 40px;
    }
  }
}
.truncated-label {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
