.manage-users {
    .back-text {
        display: block;
        margin-bottom: 15px;
    }
    .breadcrumbs-spaces {
        .breadcrumb {
            margin-bottom: 10px !important;
        }
    }
}
