.pagination-wrapper {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  .pages {
    .pagination {
      .page-item {
        button {
          min-width: 25px !important;
          color: #122048;
          font-weight: 100;
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 25px;
          cursor: pointer;
          margin: 3.5px;
          background: transparent;
          border: 0px;
          cursor: pointer;
          &:disabled {
            color: #7a7a7a;
            opacity: 0.5;
          }
        }

        &.active {
          border-color: #3db3e2;
          background-color: #3db3e2;
          border-radius: 50%;
          button {
            color: #ffffff;
          }
        }
        &:first-child,
        &:last-child {
          button {
            padding: 6px 11px;
            font-size: 14px;
            margin: 3.5px;
          }
        }
      }
    }
  }
}
