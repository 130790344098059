.qc-queue {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 'header' 'main'; }
  .qc-queue-content {
    background: #FFFFFF;
    padding: 8px 16px 0px 16px;
    grid-area: main;
    overflow: auto; }
  .qc-queue .check-button {
    position: absolute;
    padding: 8px;
    top: 0;
    left: 0; }
  .qc-queue .assign-popup {
    position: absolute;
    bottom: 76px;
    left: 11px;
    right: 11px;
    border: 1px solid #959697;
    border-radius: 4px;
    height: auto;
    z-index: 999999; }
    .qc-queue .assign-popup .card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
  .qc-queue .divider {
    height: 1px;
    background-color: #ececf0;
    margin: 16px; }
  .qc-queue-card-unit {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 16px; }
