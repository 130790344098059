.setup-Staff-user-stepper-modal {
  .sub-message {
    margin-top: 30px;
    text-align: center;
  }

  .input-label {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.36px;
  }

  .input-label-two {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 7.5px;
  }

  input {
    &.form-control {
      width: 100%;
      box-sizing: border-box;
      height: 35px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      padding: 13px 10px;
      outline: none;
      box-shadow: none;
      font-family: Avenir;
      &:hover {
        border-color: #b5b5b5;
      }
      &:focus {
        border-color: #b5b5b5;
      }
    }
    &::placeholder {
      color: #8e8e8e !important;
      opacity: 1;
    }
    ::–webkit-input-placeholder {
      color: #8e8e8e !important;
      opacity: 1;
    }
  }
}
