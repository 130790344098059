.update-password-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C1C5C9;
  .update-password-container {
    max-width: 450px;
    width: 100%;
    min-height: 300px;
    background-color: #ffffff;
    border: 2px solid transparent;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 40px 0px;
    padding: 25px;
    .top-header-section {
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
      img {
        max-width: 200px;
      }
    }
    h2 {
      font-size: 28px;
      font-family: 'Avenir', 'Roboto';
      color: #122048;
      line-height: 33px;
      text-transform: capitalize;
      text-align: center;
    }
    .form-container {
      margin-top: 40px;
      .form-group {
        margin-bottom: 20px;
        flex-direction: column;
        align-items: start;
        input {
          &.form-control {
            width: 100%;
            box-sizing: border-box;
            height: 45px;
            border-radius: 3px;
            border: 1px solid #8d8f94;
            padding: 13px 17px;
            outline: none;
            font-family: 'Avenir', 'Roboto';
            &:focus {
              border-color: #3db3e2;
            }
          }
        }
        button {
          width: 100% !important;
          font-family: 'Avenir', 'Roboto';
        }
      }
      .error-wrapper {
        margin: 15px 0px;
        .help-block {
          &.text-danger {
            text-transform: none;
          }
        }
      }
    }
  }
}
