.form-group {
  display: flex;
  align-items: center;
  justify-content: start;
  label {
    color: #122048;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    font-family: Avenir;
  }
  .input-field {
    input {
      &.form-control {
        width: 100%;
        box-sizing: border-box;
        height: 45px;
        border-radius: 3px;
        border: 1px solid #8d8f94;
        padding: 13px 17px;
        outline: none;
        font-family: Avenir;
        &:focus {
          border-color: #3db3e2;
        }
      }
    }
    select {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #8d8f94;
      padding: 10px 17px;
      outline: none;
      &:focus {
        border-color: #3db3e2;
      }
    }
  }
}

.help-block {
  &.text-danger {
    color: #f14668;
  }
}

.basic-multi-select > div {
  min-height: 45px;
  border-radius: 3px;
  border: 1px solid #8d8f94;
  box-sizing: border-box;
  outline: none;
  &:focus,
  &:hover {
    border-color: #3db3e2;
  }
}

.alert {
  width: 100%;
  border-radius: 3px;
}

.vendor-modal {
  p {
    &.modal-sync-title {
      font-family: Avenir, 'Roboto';
      color: #122048;
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
    }
  }

  .modal-card {
    overflow: initial !important;
    .modal-card-body {
      overflow: initial !important;
    }
  }
}
