.vendor-approved {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 'header' 'main'; }
  .vendor-approved-content {
    background: #FFFFFF;
    padding: 16px 16px 0 16px;
    grid-area: main;
    overflow: auto; }
    .vendor-approved-content table.table {
      background-color: #f8f9fa; }
      .vendor-approved-content table.table td {
        padding-top: 1rem;
        padding-bottom: 1rem; }
  .vendor-approved-table {
    margin-top: 24px; }
  .vendor-approved-empty {
    height: calc(100vh - 215px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
