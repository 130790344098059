.turnboard-filter-wrapper {
  margin: 0px 0px 0px 0px;
  .dropdown-trigger {
    button {
      text-transform: capitalize;
      margin-left: 24px;
      border: 1px solid #e5e9f2 !important;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #122048;
      padding: 9px;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
