.qc-approved {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 'header' 'main'; }
  .qc-approved-content {
    background: #FFFFFF;
    padding: 8px 16px 0px 16px;
    grid-area: main;
    overflow: auto; }
    .qc-approved-content table.table {
      background-color: #f8f9fa;
      color: #0d264f;
      font-weight: 400; }
      .qc-approved-content table.table td {
        padding-top: 1rem;
        padding-bottom: 1rem; }
  .qc-approved .divider {
    height: 1px;
    background-color: #ececf0;
    margin: 16px; }
  .qc-approved .highlight-text {
    color: #d25b4e;
    margin-left: 5px; }
