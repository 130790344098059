.turnboard-change-service-options-modal {
  min-height: 150px !important;
  input[type="radio"] {
    &+.custom-radio {
      border: 1px solid #91989E;
      width: 12px;
      height: 12px;
      cursor: pointer;

      &:after {
        background-color: #122048;
        width: 8px;
        height: 8px;
      }
    }

    &:checked {
      &+.custom-radio {
        background-color: #ffffff;
      }
    }
  }
  .checkbox-inner {
    border-radius: 50% !important;
    background-color: transparent !important;
    border-color: #91989E !important;
  }
  .checkbox-input.is-active {
    .checkbox-inner {
      &::after {
        border: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: #132048;
        left: 1px;
      }
    }
  }
  .notification {
    align-items: center;
    padding: 10px 15px !important;
    font-size: 12px;
    margin-top: 0px;
    button {
        background-color: transparent;
        border: none;
        padding: 0px;
        cursor: pointer;
        margin-left: auto;
    }
  }
  .error-section {
    margin: 0px !important;
    margin-bottom: 8px !important;
  }
  .modal-sync-title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #122048;
  }

  .service-selection {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    label {
      margin-right: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #122048;
    }
    select {
      cursor: pointer;
    }
  }

  .turnboard-container {
    height: 371px;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px;
    margin-top: 8px;
    border-radius: 3px;
    margin-bottom: 24px;
    .no-service-data {
      display: flex;
      justify-content: center;
      margin-top: 10px; 
      margin-bottom: 10px; 
      p {
        font-size: 15px;
        font-weight: 500;
        color: #122048;
      }
    }

    .turnboard-table-wrapper {
      width: 100%;
      height: 362px;
      display: flex;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 7px;
        background-color: transparent;
        height: 7px;
        position: absolute;
        right: 0;
      }
       
      &::-webkit-scrollbar-track {
        box-shadow: transparent;
        background-color: transparent;
      }
       
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 7px;
        outline: none;
      }
      .turnboard-item-wrapper {
        display: flex;
        width: auto;
        flex-direction: column;
        .turnboard-item-title {
          color: #ffffff;
          width: 100%;
          text-align: center;
          padding: 8px 0px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 16px;
          position: relative;
        }

        .radio-selection-header {
          height: 22px;
          background-color: #ffffff;
        }
        
      }

      .unit-table {
        width: max-content;
        position: sticky;
        left: 0;
        z-index: 999;
        .data-table-wrapper {
          .data-table-body {
            .rdt_TableCell {
              //min-width: 50px;
              div {
                &:first-child {
                  white-space: inherit;
                }
              }
            }
          }
        }
      }
      #service-table-id {
        width: 100% !important;
      }
      .na-table {
        width: 50px !important;
        .data-table-wrapper {
          .data-table-body {
            .rdt_TableCell {
              div {
                &:first-child {
                  white-space: inherit;
                }
              }
            }
            .rdt_Table {
              border: 0 !important;
            }
          }
        }
      }
      .service-table {
        width: auto !important;
        .data-table-container  {
         min-width: 800px;
        }
      }
    }
  }
  [data-checkbox]:checked:after{
    display: none;
  }
  .is-pointer {
    cursor: pointer;
  }
  .disable-content {
    pointer-events: none;
    opacity: 0.1;
  }
  .confirmation-container {
    background-color: #ffffff;
    color: #122048;
    width: 481px;
    position: absolute;
    top: 15%;
    left: 32%;
    z-index: 1016;
    padding: 24px 40px;
    border: 1px solid #E5E9F2;
    .close {
      text-align: end;
    }
    h2 {
      font-size: 24px;
      line-height: 33px;
    }
    .content {
      margin-top: 24px;
      margin-bottom: 40px;
      p {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.36px;
        span {
          font-weight: bold;
        }
      }
    }
    .helper-text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.36px;
      text-align: center;
      display: block;
      margin-bottom: 24px;
    }
  }
}
