.label-item {
  border: 1px solid #e0e66b;
  border-radius: 12px;
  background-color: #e0e66b;
  padding: 3px 8px 3px;
  .label-item-text {
    color: #ffffff;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    font-weight: 700;
  }
}

.request-title-link {
  text-decoration-line: underline;
}
