.topbar {
  &.navbar {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    transition: left 500ms;

    &.is-open {
      left: 275px;
    }

    .navbar-start {
      padding-left: 40px;
    }
    .navbar-end {
      padding-right: 40px;
    }

    .navbar-item {
      .navbar-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
    .navbar-item:last-child {
      .navbar-link {
        padding-left: 0.5rem;
        padding-right: 0.75rem;
      }
    }
  }

  // Topbar touch style is different from bulma default navbar style.
  // So here re-adding these bulma dup css
  @media screen and (min-width: 250px) {
    .navbar,
    .navbar-menu,
    .navbar-start,
    .navbar-end {
      align-items: stretch;
      display: flex;
    }

    .navbar-end {
      justify-content: flex-end;
      margin-left: auto;
    }

    .navbar-menu {
      flex-grow: 1;
      flex-shrink: 0;
      padding: 0;
      height: 64px;
    }

    .navbar-item,
    .navbar-link {
      align-items: center;
      display: flex;
    }

    .navbar-dropdown-setting {
      background-color: white;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      justify-content: center;
      border-top: 2px solid #e5e9f2;
      box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
      display: block;
      font-size: 0.875rem;
      
      min-width: 100%;
      position: absolute;
      top: 100%;
      z-index: 20;

      .navbar-item {
        padding: 0.375rem 1rem;
        white-space: nowrap;
      }
      a.navbar-item {
        padding-right: 3rem;
      }
      a.navbar-item:focus,
      a.navbar-item:hover {
        background-color: whitesmoke;
        color: #0a0a0a;
      }
    }
    .navbar-dropdown {
      background-color: white;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 2px solid #e5e9f2;
      box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
      display: none;
      font-size: 0.875rem;
      left: 0;
      min-width: 100%;
      position: absolute;
      top: 100%;
      z-index: 20;

      .navbar-item {
        padding: 0.375rem 1rem;
        white-space: nowrap;
      }
      a.navbar-item {
        padding-right: 3rem;
      }
      a.navbar-item:focus,
      a.navbar-item:hover {
        background-color: whitesmoke;
        color: #0a0a0a;
      }
    }
    .navbar-item.is-active .navbar-dropdown,
    .navbar-item.is-hoverable:focus .navbar-dropdown,
    .navbar-item.is-hoverable:focus-within .navbar-dropdown,
    .navbar-item.is-hoverable:hover .navbar-dropdown {
      display: block;
    }
  }

  .navbar-item {
    img {
      max-height: unset;
      cursor: pointer;
    }

    img.big-icon {
      width: 2.5rem;
    }

    .is-dark-blue {
      color: #2b385c;
    }
  }
}

.toolbar-dropdown {
  width: 200px;
}

.toolbar-container {
  padding: 0.5rem 1rem !important;
  li {
    margin-bottom: 0.5rem;
  }
}
